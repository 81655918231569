import React, { useEffect, useState } from "react";
import qs from "qs";
import Backendless from "backendless";
import {
  Alert,
  Form,
  FormInput,
  FormGroup,
  FormTextarea,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "shards-react";
import BottomHeader from "../components/BottomHeader";
import { Image } from "react-bootstrap";
import headerImage from "../res/Contact-us/sub-header.jpg";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

function Success(props) {
  return (
    <Container
      style={{
        position: "absolute",
        top: 0,
        left: -20,
        maxWidth: "220%",
        justifyContent: "center",
        alignSelf: "center",
      }}
      className="dr-example-container"
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
        }}
      >
        <Image
          style={{
            width: "100%",
          }}
          fluid
          src={headerImage}
        ></Image>
      </div>
      <div
        style={{
          width: "100%",
          position: "absolute",
          top: "15em",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            fontWeight: "1000",
            color: "rgb(255,255,255)",
          }}
        >
          Thanks for your order!
        </h1>
      </div>
      <div
        style={{
          width: "100%",
          top: "30%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "30%",
        }}
      >
        <html>
          <body>
            <p>
              We appreciate your business! If you have any questions, please
              email{" "}
              <a href="mailto:shaivkamat@takeitrecycling.ca">
                shaivkamat@takeitrecycling.ca
              </a>
              .
            </p>
          </body>
        </html>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          paddingTop: "5%",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "relative",
            justifyContent: "space-between",
            paddingLeft: "5%",
            // paddingTop: "20%",
          }}
        ></div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          width: "100%",
        }}
      >
        <BottomHeader isMobile={props.isMobile} />
      </div>
    </Container>
  );
}

export default Success;
