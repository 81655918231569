import React, { useEffect, useState } from "react";
import {
  Form,
  FormInput,
  FormGroup,
  Button,
  Alert,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "shards-react";
import Backendless from "backendless";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import axios from "axios";
import "./checkout.css";
import { useHistory } from "react-router-dom";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const testPriceid = "price_1HV4e1D3bne3Hf8rJz8f0HqJ";
const prodPriceId = [
  "price_1HV4d9D3bne3Hf8rIMQtogtL",
  "price_1HZBIGD3bne3Hf8riyb1VEiM",
  "price_1HZBMJD3bne3Hf8rFWHThkkz",
]; //price_1HV4d9D3bne3Hf8rIMQtogtL
const DISTANCE_RANGES = { firstBound: 4.5, secondBound: 9, thirdBound: 15 }; //km

function Sub_home() {
  let address = "";
  let placeId = "";
  let distanceFromOrigin = 0;
  let email = "";
  let phone = "";
  let password = "";
  let paymentSession = {};
  let paymentSessionId = "";

  this.setPaymentSessionId = function (val) {
    this.paymentSessionId = val;
  };

  this.setPaymentSession = function (val) {
    this.paymentSession = val;
  };

  this.getAddress = function () {
    return address;
  };

  this.setAddress = function (val) {
    if (typeof val === "string") {
      this.address = val;
    }
  };

  this.getPlaceId = function () {
    return placeId;
  };

  this.setPlaceId = function (val) {
    if (typeof val === "string") {
      this.placeId = val;
    }
  };

  this.getDistanceFromOrigin = function () {
    return distanceFromOrigin;
  };

  this.setDistanceFromOrigin = function (val) {
    if (typeof val === "number") {
      this.distanceFromOrigin = val;
    }
  };

  this.getEmail = function () {
    return email;
  };

  this.setEmail = function (val) {
    this.email = val;
  };

  this.getPassword = function () {
    return password;
  };

  this.setPassword = function (val) {
    this.password = val;
  };

  this.getPhone = function () {
    return phone;
  };

  this.setPhone = function (val) {
    this.phone = val;
  };
}
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function SignUp_Checkout(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [coupon, setCoupon] = useState("");
  const [altCode, setAltCode] = useState("");
  const [customer, setCustomer] = useState(null);
  const [priceData, setPriceData] = useState(null);
  const [productPriceId, setProductPriceId] = useState(prodPriceId[0]);
  const [gettingPrice, setGettingPrice] = useState(false);

  const [showStripeError, setShowStripeError] = useState(false);
  const [couponData, setCouponData] = useState(null);
  const [showCouponError, setShowCouponError] = useState(false);
  const [accountInformation, setAccountInformation] = useState(null);
  const [errorToDisplay, setErrorToDisplay] = useState("");
  const [checkingOut, setCheckingOut] = useState(false);
  const history = useHistory();
  //   const [stripeProcessing, SetStripeProcessing] = useState({});
  const subscription = new Sub_home();
  let orderData = props.getInitial();
  subscription.setAddress(orderData.address);
  subscription.setPlaceId(orderData.placeId);
  subscription.setEmail(orderData.email);
  subscription.setPassword(orderData.password);
  subscription.setPhone(orderData.phone);
  subscription.setDistanceFromOrigin(orderData.distanceFromOrigin);
  let deliveryString = ""; // 0 = no fee, 1 = $5, 2 = $10

  if (orderData.distanceFromOrigin <= DISTANCE_RANGES.firstBound) {
    deliveryString = "0";
  } else if (orderData.distanceFromOrigin <= DISTANCE_RANGES.secondBound) {
    deliveryString = "1";
  } else if (orderData.distanceFromOrigin <= DISTANCE_RANGES.thirdBound) {
    deliveryString = "2";
  }

  if (priceData === null && !gettingPrice) {
    setGettingPrice(!gettingPrice);
    axios
      .post(
        `https://api.backendless.com/FCA9F083-7E4C-5555-FF8A-50897ED95B00/7DD76079-D6FC-4CA3-A5C4-6556C7768783/services/StripeService/getPrice`,
        productPriceId
      )
      .then(async (res) => {
        setPriceData(res.data);
        setGettingPrice(!gettingPrice);
      })
      .catch((err) => {
        setGettingPrice(!gettingPrice);
        console.log(err);
      });
  }

  function onSubscriptionComplete(result) {
    console.log(result);
    // Payment was successful. Provision access to your service.
    // Remove invoice from localstorage because payment is now complete.
    // clearCache();
    if (result && !result.subscription) {
      const subs = { id: result.invoice.subscription };
      result.subscription = subs;
      localStorage.clear();
    }
    let user = new Backendless.User();
    user.email = orderData.email;
    user.identity = orderData.email;
    user.password = orderData.password;
    user.placeId = orderData.placeId;
    user.distanceFromOrigin = orderData.distanceFromOrigin;
    user.address = orderData.address;
    user.phone = orderData.phone;
    user.subscription = result;
    user.subscriptionStatus =
      result.subscription.latest_invoice.payment_intent.status;
    user.customerId = result.subscription.customer;

    setAccountInformation(result);

    console.log(user);
    Backendless.UserService.register(user)
      .then(function (regesteredUser) {
        console.log(regesteredUser);
        setCheckingOut(false);
        alert(
          "You'll be redirected to login to your account,\n Thanks for your suppot!"
        );
        history.push("/login");
      })
      .catch(function (error) {
        console.log(error);
        ForceTryRegister(user);
      });
    // Change your UI to show a success message to your customer.
    // onSubscriptionSampleDemoComplete(result);
    // Call your backend to grant access to your service based on
    // the product your customer subscribed to.
    // Get the product by using result.subscription.price.product
  }

  function ForceTryRegister(user) {
    Backendless.UserService.register(user)
      .then(function (regesteredUser) {
        console.log(regesteredUser);
        setCheckingOut(false);
        history.push("/login");
      })
      .catch(function (error) {
        console.log(error);
        ForceTryRegister(user);
      });
  }

  function createSubscription({ paymentMethodId }) {
    const priceId = productPriceId;
    let distanceZone = ""; // 0 = no fee, 1 = $5, 2 = $10

    if (orderData.distanceFromOrigin <= DISTANCE_RANGES.firstBound) {
      distanceZone = "0";
    } else if (orderData.distanceFromOrigin <= DISTANCE_RANGES.secondBound) {
      distanceZone = "1";
    } else if (orderData.distanceFromOrigin <= DISTANCE_RANGES.thirdBound) {
      distanceZone = "2";
    }

    setCheckingOut(true);
    return axios
      .post(
        `https://api.backendless.com/FCA9F083-7E4C-5555-FF8A-50897ED95B00/7DD76079-D6FC-4CA3-A5C4-6556C7768783/services/StripeService/createCustomer`,
        orderData.email
      )
      .then(async (res) => {
        console.log(res);
        setCustomer(res.data);
        axios
          .post(
            `https://api.backendless.com/FCA9F083-7E4C-5555-FF8A-50897ED95B00/7DD76079-D6FC-4CA3-A5C4-6556C7768783/services/StripeService/createSubscription`,
            {
              customerId: res.data.id,
              paymentMethodId: paymentMethodId,
              priceId: priceId,
              deliveryString: distanceZone,
              promotionCode: couponData === null ? "false" : couponData.id,
            }
          )
          .then((response) => {
            console.log(response);
            return response;
          })
          // If the card is declined, display an error to the user.
          .then((result) => {
            if (result.error) {
              setShowStripeError(true);
              // The card had an error when trying to attach it to a customer
              throw result;
            }
            return result;
          })
          // Normalize the result to contain the object returned
          // by Stripe. Add the addional details we need.
          .then((result) => {
            console.log(result);
            return {
              // Use the Stripe 'object' property on the
              // returned result to understand what object is returned.
              subscription: result.data,
              paymentMethodId: paymentMethodId,
              priceId: priceId,
              customer: res.data,
            };
          })
          // Some payment methods require a customer to do additional
          // authentication with their financial institution.
          // Eg: 2FA for cards.
          .then(handlePaymentThatRequiresCustomerAction)
          // If attaching this card to a Customer object succeeds,
          // but attempts to charge the customer fail. You will
          // get a requires_payment_method error.
          .then(handleRequiresPaymentMethod)
          // No more actions required. Provision your service for the user.
          .then(onSubscriptionComplete)
          .catch((error) => {
            setCheckingOut(false);
            console.log(error);
            // An error has happened. Display the failure to the user here.
            // We utilize the HTML element we created.
            setErrorToDisplay(error.message || error.error.decline_code);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId,
  }) {
    if (subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      "requires_payment_method"
    ) {
      setShowStripeError(true);
      throw new Error(
        "There was an issue processing your information, please double check and contact us at shaivkamat@gmail.com"
      );
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) {
    if (subscription && subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }
    setShowStripeError(true);
    throw new Error(
      "There was an issue processing your information, please double check and contact us at shaivkamat@gmail.com"
    );
  }

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    if (!elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    // If a previous payment was attempted, get the latest invoice
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    if (error) {
      console.log("[createPaymentMethod error]", error);
    } else {
      console.log("[PaymentMethod]", paymentMethod);
      const paymentMethodId = paymentMethod.id;
      // Create the subscription
      createSubscription({
        paymentMethodId: paymentMethodId,
      });
    }
  };
  useEffect(() => {
    console.log(coupon);
    async function getCoupons() {
      await axios
        .post(
          `https://api.backendless.com/FCA9F083-7E4C-5555-FF8A-50897ED95B00/7DD76079-D6FC-4CA3-A5C4-6556C7768783/services/StripeService/getPromotionCodes`,
          coupon
        )
        .then((res) => {
          console.log(res);
          if (res.data.data.length > 0) {
            setCouponData(res.data.data[0]);
            setShowCouponError(false);
          } else {
            setCouponData(null);
            setShowCouponError(true);
          }
        });
    }
    if (coupon.length > 3) {
      getCoupons();
    } else {
      setCouponData(null);
      setShowCouponError(false);
    }
  }, [coupon]);

  const handleCouponChange = (coupon) => {
    setCoupon(coupon.target.value);
  };

  //retrieve prodId for valid Alt Codes
  const handleAltCodeChange = (data) => {
    setAltCode(data.target.value);
    if (data.target.value === "EARLYBIRDSPECIAL") {
      setProductPriceId(prodPriceId[2]);
      setPriceData(null);
      setGettingPrice(false);
    } else if (data.target.value === "EARLYBIRD") {
      setProductPriceId(prodPriceId[1]);
      setPriceData(null);
      setGettingPrice(false);
    } else {
      setProductPriceId(prodPriceId[0]);
      setPriceData(null);
      setGettingPrice(false);
    }
  };
  let deliveryCost = ""; // 0 = no fee, 1 = $5, 2 = $10

  if (orderData.distanceFromOrigin <= DISTANCE_RANGES.firstBound) {
    deliveryCost = "";
  } else if (orderData.distanceFromOrigin <= DISTANCE_RANGES.secondBound) {
    deliveryCost = "1";
  } else if (orderData.distanceFromOrigin <= DISTANCE_RANGES.thirdBound) {
    deliveryCost = "2";
  }

  let displayPrice = "";
  if (priceData) {
    if (couponData === null) {
      displayPrice = priceData.unit_amount / 100.0;
    } else if (couponData.coupon.amount_off !== null) {
      displayPrice =
        priceData.unit_amount / 100.0 - couponData.coupon.amount_off / 100.0;
    } else {
      displayPrice =
        (priceData.unit_amount / 100.0) *
        ((100 - couponData.coupon.percent_off) / 100.0);
    }
  }

  return (
    <div
      style={{
        width: 600,
      }}
    >
      <Alert
        dismissible={() => {
          setShowStripeError(false);
        }}
        theme="warning"
        open={showStripeError}
      >
        There was an issue processing your payments, please double check your
        info. Email us at <strong>shaivkamat@gmail.com</strong> for assistance
      </Alert>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {priceData && (
          <div style={{ width: 300 }}>
            <Card>
              <CardBody>
                <CardTitle>
                  Monthly Pickup Service ( $ {displayPrice} + ${" "}
                  {deliveryCost === "1" ? 5 : 10})
                </CardTitle>
                {couponData && (
                  <CardSubtitle>Adjusted with Coupon</CardSubtitle>
                )}
                <CardSubtitle>
                  Service will begin once payment is confirmed.{" "}
                </CardSubtitle>
                <CardSubtitle
                  style={{
                    display: deliveryCost !== "0" ? "" : "none",
                    paddingTop: "2em",
                  }}
                >
                  Distance based fee of ( $ {deliveryCost === "1" ? 5 : 10}){" "}
                </CardSubtitle>
              </CardBody>
            </Card>
          </div>
        )}
      </div>
      <label>Card details</label>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
      <Form>
        <label htmlFor="#coupon">Coupon Code</label>
        <FormInput
          id="#coupon"
          type="text"
          placeholder="Coupon Code"
          defaultValue={coupon}
          onChange={handleCouponChange}
        />
        {/* <label htmlFor="#alt">Alt Code</label>
        <FormInput
          id="#alt"
          type="text"
          placeholder="Alt Code"
          defaultValue={altCode}
          onChange={handleAltCodeChange}
        /> */}
        {showCouponError && (
          <label>
            The entered coupon doesn't exist (coupons are case senstive)
          </label>
        )}
        <FormGroup>
          <Button
            pill
            theme="success"
            disabled={checkingOut}
            onClick={handleSubmit}
          >
            Checkout
          </Button>
        </FormGroup>
      </Form>
    </div>
  );
}

export default SignUp_Checkout;
