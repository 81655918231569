import React from "react";
import { Form, FormInput, FormGroup, Alert, FormSelect } from "shards-react";
import PhoneInput from "react-phone-number-input/input";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const DISTANCE_FLAG_KM = 6.5; //ORG: 20
const DISTANCE_RANGES = { firstBound: 6.5, secondBound: 12.5, thirdBound: 20 };
class Book_OTP_InfoStage extends React.Component {
  constructor(props) {
    super(props);
    this.dismiss = this.dismiss.bind(this);
    this.handleContactSelect = this.handleContactSelect.bind(this);
    let initialVals = this.props.getInitial(1);
    if (!initialVals)
      this.state = {
        visible: false,
        showDistWarn: false,
        address: "",
        placeId: "",
        gmapsLoaded: false,
        distanceFromOrigin: 0,
        contactValue: "",
        contactType: "",
      };
    else
      this.state = {
        visible: false,
        showDistWarn: false,
        address: initialVals.address,
        placeId: initialVals.placeId,
        gmapsLoaded: false,
        distanceFromOrigin: initialVals.distanceFromOrigin,
        contactValue: initialVals.contactValue,
        contactType: initialVals.setContactType,
      };
  }

  initMap = () => {
    this.setState({
      gmapsLoaded: true,
    });
  };

  componentDidMount() {
    window.initMap = this.initMap;
    const gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAzMZnr9fed5nZBhqXCQjZbQTljErOGv0s&libraries=places&callback=initMap`;
    document
      .querySelector(`body`)
      .insertAdjacentElement(`beforeend`, gmapScriptEl);
  }
  getDistanceFromLatLonInKm = (lat2, lon2) => {
    function deg2rad(deg) {
      return deg * (Math.PI / 180);
    }
    var R = 6371; // Radius of the earth in km
    var origin = { lat: 49.270163, lon: -123.172084 };
    var dLat = deg2rad(lat2 - origin.lat); // deg2rad below
    var dLon = deg2rad(lon2 - origin.lon);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(origin.lat)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  };

  dismiss() {
    this.setState({ visible: false });
  }

  dismissWarning() {
    this.setState({ showDistWarn: false });
  }

  handleContactSelect(event) {
    this.setState(
      {
        contactType: event.target.value,
        contactValue: "",
      },
      () => {
        // console.log(this.state);
      }
    );
    this.props.setContactType(event.target.value);
    this.props.setContactValue("");
  }

  handleChange = (address) => {
    this.setState({ address, distanceFromOrigin: 0, placeId: "" });
  };

  handleEmailChange = (contactValue) => {
    this.setState({ contactValue: contactValue.target.value });
    this.props.setContactValue(contactValue.target.value);
  };

  handleContactChange = (contactValue) => {
    this.setState({ contactValue });
    this.props.setContactValue(contactValue);
  };

  handleSelect4 = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        this.setState({
          placeId: results[0].place_id,
          address: results[0].formatted_address,
        });
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        let dist = this.getDistanceFromLatLonInKm(latLng.lat, latLng.lng);
        if (dist > DISTANCE_RANGES.thirdBound) {
          this.setState({
            address: "",
            distanceFromOrigin: 0,
            placeId: "",
            visible: true,
          });
        } else {
          let req = { distanceFromOrigin: dist };
          if (dist > DISTANCE_RANGES.firstBound) {
            req.showDistWarn = true;
          }
          this.setState(req);
          console.log(
            "Success",
            latLng,
            this.getDistanceFromLatLonInKm(latLng.lat, latLng.lng)
          );
        }
      })
      .catch((error) => console.error("Error", error));
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        this.setState({
          placeId: results[0].place_id,
          address: results[0].formatted_address,
        });

        return getLatLng(results[0]);
      })
      .then((data) => {
        let dist = this.getDistanceFromLatLonInKm(data.lat, data.lng);
        console.log(JSON.stringify(this.state.address, null, 4));
        if (dist > DISTANCE_RANGES.thirdBound) {
          this.setState({
            address: "",
            distanceFromOrigin: 0,
            placeId: "",
            visible: true,
          });
          this.props.setAddress("");
          this.props.setPlaceId("");
          this.props.setDistanceFromOrigin(0);
        } else {
          let req = { distanceFromOrigin: dist };
          if (dist > DISTANCE_RANGES.firstBound) {
            req.showDistWarn = true;
          }
          this.setState(req);

          this.props.setAddress(this.state.address);
          this.props.setPlaceId(this.state.placeId);
          this.props.setDistanceFromOrigin(dist);
          console.log(
            "Success",
            data,
            this.getDistanceFromLatLonInKm(data.lat, data.lng)
          );
        }
      })
      .catch((error) => console.error("Error", error));
  };

  render() {
    let contactInput;

    if (this.state.contactType === "phone")
      contactInput = (
        <div>
          <label htmlFor="">Phone Number</label>
          <PhoneInput
            placeholder="Enter phone number"
            value={this.state.contactValue}
            onChange={this.handleContactChange}
            defaultCountry="CA"
          />
        </div>
      );

    if (this.state.contactType === "email")
      contactInput = (
        <div>
          <label htmlFor="">Email Address</label>
          <FormInput
            type="contact"
            placeholder="Contact"
            onChange={this.handleEmailChange}
          />
        </div>
      );

    const google = window.google;
    return (
      <Form>
        <Alert dismissible={this.dismiss} open={this.state.visible}>
          Sorry, we only pick up in <strong> Metro Vancouver </strong>;
        </Alert>
        <Alert dismissible={this.dismissWarning} open={this.state.showDistWarn}>
          There is a Driver fee of{" "}
          <strong>
            {" "}
            {this.distanceFromOrigin > DISTANCE_RANGES.secondBound
              ? "$20"
              : "$10"}{" "}
          </strong>{" "}
          for this zone;
        </Alert>
        <FormGroup>
          {this.state.gmapsLoaded && (
            <div>
              <label htmlFor="">Pickup Address</label>
              <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                searchOptions={{
                  origin: new google.maps.LatLng(49.250289, -123.031485),
                  location: new google.maps.LatLng(49.250289, -123.031485),
                  componentRestrictions: {
                    country: ["ca"],
                  },
                  radius: 20000,
                  types: ["address"],
                }}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <FormInput
                      {...getInputProps({
                        placeholder: "Search Places ...",
                        className: "location-search-input",
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
          )}
        </FormGroup>
        <label htmlFor="#FormSelect">Perferred Method of Contact</label>
        <FormSelect size="lg" onChange={this.handleContactSelect}>
          <option selected>Select Method of Contact</option>
          <option value="email">Email</option>
          <option value="phone">Phone Number</option>
          <option value="wut" disabled>
            Fax?
          </option>
        </FormSelect>
        <FormGroup></FormGroup>
        {contactInput}
      </Form>
    );
  }
}

export default Book_OTP_InfoStage;
