import React, { useEffect, useState } from "react";
import { Form, FormInput, FormGroup, Button } from "shards-react";
import Backendless from "backendless";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const DISTANCE_RANGES = { firstBound: 4.5, secondBound: 9, thirdBound: 15 }; //km

function OTP_home() {
  let address = "";
  let placeId = "";
  let distanceFromOrigin = 0;
  let pickUpDate;
  let pickUpTime = "";
  let contactMethodType = "";
  let contactMethodValue = "";
  let paymentSession = {};
  let paymentIntentId = "";
  let paymentStatus = false;
  let paymentSessionId = "";
  let jobData = {};
  this.setJob = function (data) {
    this.jobData = data;
  };

  this.setPaymentSessionId = function (val) {
    this.paymentSessionId = val;
  };

  this.setPaymentStatus = function (val) {
    this.paymentStatus = val;
  };

  this.getPaymentStatus = function () {
    return this.paymentStatus;
  };

  this.setPaymentIntentId = function (val) {
    this.paymentIntentId = val;
  };

  this.setPaymentSession = function (val) {
    this.paymentSession = val;
  };

  this.getAddress = function () {
    return address;
  };

  this.setAddress = function (val) {
    if (typeof val === "string") {
      this.address = val;
    }
  };

  this.getPlaceId = function () {
    return placeId;
  };

  this.setPlaceId = function (val) {
    if (typeof val === "string") {
      this.placeId = val;
    }
  };

  this.getDistanceFromOrigin = function () {
    return distanceFromOrigin;
  };

  this.setDistanceFromOrigin = function (val) {
    if (typeof val === "number") {
      this.distanceFromOrigin = val;
    }
  };

  this.getPickUpDate = function () {
    return pickUpDate;
  };

  this.setPickUpDate = function (val) {
    this.pickUpDate = val;
  };

  this.getPickUpTime = function () {
    return pickUpTime;
  };

  this.setPickUpTime = function (val) {
    this.pickUpTime = val;
  };

  this.getContactMethodType = function () {
    return contactMethodType;
  };

  this.setContactMethodType = function (val) {
    if (typeof val === "string") {
      this.contactMethodType = val;
    }
  };
  this.getContactMethodValue = function () {
    return contactMethodValue;
  };

  this.setContactMethodValue = function (val) {
    if (typeof val === "string") {
      this.contactMethodValue = val;
    }
  };
}

function Book_OTP_Checkout(props) {
  const [stripeProcessing, SetStripeProcessing] = useState({});
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    async function fetchConfig() {
      // Fetch config from our backend.

      const stripe = await loadStripe(
        "pk_live_51HQTtsD3bne3Hf8rb6EC1SWOEo3lhfKPnTBHIYaZMpqYOVTITLwqAanrR5gRIwSL1pbnQmO4J3qFewsOsdWwpEBj00i85fr8YU"
      );
      SetStripeProcessing(stripe);
    }
    fetchConfig();
  }, []);
  const oneTimePickup = new OTP_home();
  let orderData = props.getInitial(3);
  oneTimePickup.setAddress(orderData.address);
  oneTimePickup.setPlaceId(orderData.placeId);
  oneTimePickup.setPickUpDate(orderData.pickupDate);
  oneTimePickup.setPickUpTime(orderData.pickupTime);
  oneTimePickup.setDistanceFromOrigin(orderData.distanceFromOrigin);
  oneTimePickup.setContactMethodType(orderData.contactType);
  oneTimePickup.setContactMethodValue(orderData.contactValue);
  oneTimePickup.setJob(orderData.job);
  oneTimePickup.setPaymentStatus(false);
  let deliveryString = ""; // 0 = no fee, 1 = $5, 2 = $10

  if (orderData.distanceFromOrigin <= DISTANCE_RANGES.firstBound) {
    deliveryString = "0";
  } else if (orderData.distanceFromOrigin <= DISTANCE_RANGES.secondBound) {
    deliveryString = "1";
  } else if (orderData.distanceFromOrigin <= DISTANCE_RANGES.thirdBound) {
    deliveryString = "2";
  }

  return (
    <Form>
      <FormGroup>
        <Button
          pill
          theme="success"
          disabled={submitting}
          onClick={async () => {
            setSubmitting(true);
            axios
              .post(
                `https://api.backendless.com/FCA9F083-7E4C-5555-FF8A-50897ED95B00/057A56B2-1ED2-4C43-B308-556D2E4C308B/services/StripeService/createSession`,
                {
                  contactType: orderData.contactType,
                  contactValue: orderData.contactValue,
                  deliveryString,
                }
              )
              .then(async (res) => {
                oneTimePickup.setPaymentSession(res.data);
                oneTimePickup.setPaymentIntentId(res.data.payment_intent);
                oneTimePickup.setPaymentSessionId(res.data.id);
                await Backendless.Data.of("OTP_home").save(oneTimePickup);
                const result = await stripeProcessing.redirectToCheckout({
                  sessionId: res.data.id,
                });

                if (result.error) {
                  setSubmitting(false);
                  // If `redirectToCheckout` fails due to a browser or network
                  // error, display the localized error message to your customer
                  // using `result.error.message`.
                } else {
                  setSubmitting(false);
                  console.log(result);
                  console.log(JSON.stringify(result, null, 4));
                }
              });
          }}
        >
          Checkout
        </Button>
      </FormGroup>
    </Form>
  );
}

export default Book_OTP_Checkout;
