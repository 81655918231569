import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { Logo, Input, Error } from "../components/AuthForm";
import { useAuth } from "../context/auth";
import {
  Alert,
  Form,
  FormInput,
  FormGroup,
  FormTextarea,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "shards-react";
import BottomHeader from "../components/BottomHeader";
import { Image } from "react-bootstrap";
import headerImage from "../res/Contact-us/sub-header.jpg";
import Backendless from "backendless";
import { useMachineValue } from "../machines";

function Login(props) {
  const [{ context }, send] = useMachineValue();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState(""); //shaivkamat@gmail.com
  const [password, setPassword] = useState(""); //asdqwezxcasd
  const referer =
    props.location.state && props.location.state.referer
      ? props.location.state.referer
      : "/Dashboard";

  function postLogin() {
    Backendless.UserService.login(email, password, false)
      .then(function (loggedInUser) {
        // console.log(loggedInUser);
        send("LOGIN", { data: loggedInUser });
        setLoggedIn(true);
      })
      .catch(function (error) {
        console.log(error);
        setIsError(true);
      });

    // setLoggedIn(true);
    // setAuthTokens({
    //   authTokens: "loggedIn",
    // });
  }
  if (isLoggedIn) {
    return <Redirect to={referer} />;
  }

  return (
    <Container
      style={{
        position: "absolute",
        top: 0,
        left: -20,
        maxWidth: "220%",
        justifyContent: "center",
        alignSelf: "center",
      }}
      className="dr-example-container"
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
        }}
      >
        <Image
          style={{
            width: "100%",
          }}
          fluid
          src={headerImage}
        ></Image>
      </div>
      <div
        style={{
          width: "100%",
          position: "absolute",
          top: "15vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            fontWeight: "1000",
            fontSize: props.isMobile ? "5vw" : "3vw",
            color: "rgb(255,255,255)",
          }}
        >
          LOGIN
        </h1>
      </div>
      <div
        style={{
          justifyContent: "Space-around",
          alignItems: "center",
          width: 400,
        }}
      ></div>
      <div
        style={{
          width: "100%",
          top: "30%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "30%",
        }}
      >
        <div
          style={{
            justifyContent: "Space-around",
            alignItems: "center",
            width: "70%",
            paddingTop: "5%",
          }}
        >
          <Card>
            <CardBody>
              <Form>
                <FormGroup>
                  <label htmlFor="#email">Email Address</label>
                  <FormInput
                    id="#email"
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder="email"
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor="#password">Password</label>
                  <FormInput
                    type="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    placeholder="password"
                  />
                </FormGroup>
                <Button pill theme="success" onClick={postLogin}>
                  Sign In
                </Button>
              </Form>
              <Link to="/signup">Don't have an account?</Link>
              {isError && (
                <Error>The username or password provided were incorrect!</Error>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          paddingTop: "5%",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "relative",
            justifyContent: "space-between",
            paddingLeft: "5%",
            // paddingTop: "20%",
          }}
        ></div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          width: "100%",
        }}
      >
        <BottomHeader isMobile={props.isMobile} />
      </div>
    </Container>
  );
}

export default Login;
