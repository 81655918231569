import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import Backendless from "backendless";
import {
  Alert,
  Form,
  FormInput,
  FormGroup,
  FormTextarea,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "shards-react";
import Shape from "../components/PointedShape";
import ImageText from "../components/ImageText";
import BottomHeader from "../components/BottomHeader";
import { Image } from "react-bootstrap";
import cardboardPic from "../res/landing-page/cardboard-services.jpg";
import mushroomPic from "../res/landing-page/Mushroom.jpg";
import logYSprouts from "../res/landing-page/banner-imagethrd.jpg";
import headerImage from "../res/Contact-us/sub-header.jpg";
import bgSilhouette from "../res/landing-page/bg.png";
import { SocialIcon } from "react-social-icons";

var validator = require("email-validator");
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

function ContactUs(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [emailError, setShowEmailError] = useState(false);
  const [lengthError, setShowLengthError] = useState(false);
  const [emailConf, setShowEmailConf] = useState(false);

  async function handleSubmit() {
    let sendMsg = true;
    let valid = validator.validate(email);
    if (!valid) {
      setShowEmailError(true);
      sendMsg = false;
    } else {
      setShowEmailError(false);
    }
    if (subject.length === 0 || message.length === 0) {
      setShowLengthError(true);
      sendMsg = false;
    } else {
      setShowLengthError(false);
    }
    if (sendMsg) {
      var bodyParts = new Backendless.Bodyparts();
      bodyParts.textmessage = message;
      bodyParts.htmlmessage = message;

      await Backendless.Messaging.sendEmail(
        "SITE CONTACT FORM [" + email + "] SBJ: [" + subject + "]",
        bodyParts,
        ["shaivkamat@takeitrecycling.ca"]
      ).then(function (response) {
        setShowEmailConf(true);
        console.log("Email Sent");
      });
    }
  }
  return (
    <Container
      style={{
        position: "absolute",
        top: 0,
        left: -20,
        maxWidth: "220%",
        justifyContent: "center",
        alignSelf: "center",
      }}
      className="dr-example-container"
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
            position: "absolute",
            top: "15vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1
            style={{
              fontWeight: "1000",
              fontSize: props.isMobile ? "5vw" : "3vw",
              color: "rgb(255,255,255)",
              paddingBottom: "1vw",
            }}
          >
            GOT A PROJECT YOU NEED HELP WITH?
          </h1>
          <h3
            style={{
              fontWeight: "1000",
              fontSize: props.isMobile ? "2.5vw" : "1.5vw",
              color: "rgb(255,255,255)",
              display: props.isMobile ? "none" : "",
            }}
          >
            GET IN TOUCH WITH US
          </h3>
        </div>
        <Image
          style={{
            width: "100%",
            // height: props.isMobile ? "250%" : "3vw",
          }}
          fluid
          src={headerImage}
        ></Image>
      </div>
      <div>
        <span
          style={{
            color: "#2c4058",
            fontWeight: "800",
            fontSize: props.isMobile ? "4vw" : "2vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: props.isMobile ? "35%" : "30%",
          }}
        >
          CONTACT FORM
        </span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          paddingTop: "20px",
        }}
      >
        <div
          style={{
            width: "100px",
            height: "2px",
            background: "#2c3f58",
            marginBottom: "60px",
          }}
        ></div>
      </div>
      <div
        style={{
          display: "flex",
          width: "80%",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: props.isMobile ? "column" : "row",
            // justifyContent: "space-between",
            // width: "100%",
          }}
        >
          <div
            style={{
              marginLeft: props.isMobile ? "15vw" : "35vw",
              display: "flex",
              flexDirection: "column",
              width: props.isMobile ? "80vw" : "35em",
            }}
          >
            <span
              style={{
                color: "#2c4058",
                fontWeight: "800",
              }}
            >
              Address Info
            </span>
            <div
              style={{
                width: "100px",
                height: "2px",
                background: "#2c3f58",
                marginBottom: "30px",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "15px",
                alignitems: "flex-end",
              }}
            >
              <span
                style={{
                  color: "#2c4058",
                  fontWeight: "500",
                }}
              >
                Address
              </span>
              <span
                style={{
                  marginLeft: "2em",
                  color: "#2c4058",
                  fontWeight: "300",
                }}
              >
                3026 West 2nd Ave, Vancouver BC, V6K 1K4
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignitems: "flex-end",
              }}
            >
              <span
                style={{
                  marginBottom: "15px",
                  color: "#2c4058",
                  fontWeight: "500",
                }}
              >
                Phone
              </span>
              <span
                style={{
                  marginLeft: "2em",
                  color: "#2c4058",
                  fontWeight: "300",
                }}
              >
                +1 416 737 7714
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignitems: "flex-end",
                marginBottom: "15px",
              }}
            >
              <span
                style={{
                  color: "#2c4058",
                  fontWeight: "500",
                }}
              >
                Email
              </span>
              <span
                style={{
                  marginLeft: "2em",
                  color: "#2c4058",
                  fontWeight: "300",
                }}
              >
                services@takeitrecycling.ca
              </span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "5vw",
              }}
            >
              <SocialIcon
                network="twitter"
                bgColor="#2c3f58"
                fgColor="#ffffff"
                url="http://twitter.com"
                style={{ height: 25, width: 25 }}
              />
              <SocialIcon
                network="facebook"
                bgColor="#2c3f58"
                fgColor="#ffffff"
                url="http://facebook.com"
                style={{ height: 25, width: 25, marginLeft: 15 }}
              />
              <SocialIcon
                network="reddit"
                bgColor="#2c3f58"
                fgColor="#ffffff"
                url="http://reddit.com"
                style={{ height: 25, width: 25, marginLeft: 15 }}
              />
            </div>
          </div>
          <div
            style={{
              marginLeft: props.isMobile ? "15vw" : "5vw",
              width: props.isMobile ? "80vw" : "25vw",
            }}
          >
            <span
              style={{
                color: "#2c4058",
                fontWeight: "800",
              }}
            >
              Contact Us
            </span>
            <div
              style={{
                width: "100px",
                height: "2px",
                background: "#2c3f58",
                marginBottom: "30px",
              }}
            ></div>

            <Alert
              theme="warning"
              dismissible={() => {
                setShowLengthError(false);
              }}
              open={lengthError}
            >
              All * fields are <strong>Required</strong>;
            </Alert>
            <Alert
              theme="warning"
              dismissible={() => {
                setShowEmailError(false);
              }}
              open={emailError}
            >
              Please enter in a valid <strong>Email</strong> address;
            </Alert>
            <Alert
              theme="success"
              dismissible={() => {
                setShowEmailConf(false);
              }}
              open={emailConf}
            >
              Your email has been <strong>recieved</strong>;
            </Alert>
            <Form>
              <FormGroup>
                <FormInput
                  id="#email"
                  type="email"
                  placeholder="Email Address *"
                  onChange={(data) => {
                    setEmail(data.target.value);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <FormInput
                  id="#subject"
                  type="text"
                  placeholder="Subject *"
                  onChange={(data) => {
                    setSubject(data.target.value);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <FormTextarea
                  id="#msg"
                  type="text"
                  placeholder="Message *"
                  onChange={(data) => {
                    setMessage(data.target.value);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Button squared theme="success" onClick={handleSubmit}>
                  Submit
                </Button>
              </FormGroup>
            </Form>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          width: "100%",
        }}
      >
        <BottomHeader isMobile={props.isMobile} />
      </div>
    </Container>
  );
}

export default ContactUs;
