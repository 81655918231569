import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import Backendless from "backendless";
import {
  Alert,
  Form,
  FormInput,
  FormGroup,
  FormTextarea,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "shards-react";
import Shape from "../components/PointedShape";
import AboutDetails from "../components/AboutDetails";
import ImageText from "../components/ImageText";
import BottomHeader from "../components/BottomHeader";
import { Image } from "react-bootstrap";
import cardboardPic from "../res/about-us/main-image.jpg";
import mushroomPic from "../res/landing-page/Mushroom.jpg";
import logYSprouts from "../res/landing-page/banner-imagethrd.jpg";
import headerImage from "../res/Contact-us/sub-header.jpg";
import bgSilhouette from "../res/landing-page/bg.png";
import ShaivImg from "../res/about-us/HeadShots/Shaiv.jpg";
import GriffinImg from "../res/about-us/HeadShots/Griffin.jpg";
import WyattImg from "../res/about-us/HeadShots/Wyatt.jpg";
import JustinImg from "../res/about-us/HeadShots/Justin.jpg";
import { SocialIcon } from "react-social-icons";

var validator = require("email-validator");
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

function AboutUs(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [emailError, setShowEmailError] = useState(false);
  const [lengthError, setShowLengthError] = useState(false);
  const [emailConf, setShowEmailConf] = useState(false);

  async function handleSubmit() {
    let sendMsg = true;
    let valid = validator.validate(email);
    if (!valid) {
      setShowEmailError(true);
      sendMsg = false;
    } else {
      setShowEmailError(false);
    }
    if (subject.length === 0 || message.length === 0) {
      setShowLengthError(true);
      sendMsg = false;
    } else {
      setShowLengthError(false);
    }
    if (sendMsg) {
      var bodyParts = new Backendless.Bodyparts();
      bodyParts.textmessage = message;
      bodyParts.htmlmessage = message;

      await Backendless.Messaging.sendEmail(
        "SITE CONTACT FORM [" + email + "] SBJ: [" + subject + "]",
        bodyParts,
        ["shaivkamat@takeitrecycling.ca"]
      ).then(function (response) {
        setShowEmailConf(true);
        console.log("Email Sent");
      });
    }
  }
  return (
    <Container
      style={{
        position: "absolute",
        top: 0,
        left: -20,
        maxWidth: "220%",
        justifyContent: "center",
        alignSelf: "center",
      }}
      className="dr-example-container"
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
        }}
      >
        <Image
          style={{
            width: "100%",
          }}
          fluid
          src={headerImage}
        ></Image>
      </div>
      <div
        style={{
          width: "100%",
          position: "absolute",
          top: "15vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            fontWeight: "1000",
            fontSize: props.isMobile ? "5vw" : "3vw",
            color: "rgb(255,255,255)",
          }}
        >
          ABOUT US
        </h1>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "80%",
            paddingLeft: "5%",
            paddingTop: "35%",
          }}
        >
          <div
            style={{
              display: "block",
              flexDirection: "row",
              position: "relative",
              alignSelf: "flex-start",
              maxWidth: "220%",
              width: props.isMobile ? "10em" : "37em",
              // height: "475px",
              background: "rgb(150, 213, 89)",
              marginBottom: "60px",
              paddingLeft: "5%",
              paddingTop: "20px",
            }}
          >
            <Shape>
              <div
                style={{
                  position: "relative",
                  top: "-150px",
                  left: "0px",
                  width: props.isMobile ? "250%" : "150%",
                }}
              >
                <ImageText
                  imageSrc={cardboardPic}
                  flip={false}
                  title="Our Mission"
                  link=""
                  btn="none"
                  btnText=""
                  isMobile={props.isMobile}
                  text="If you’re like us, you probably go through more cardboard than you know what to do
                  with. After the start of the COVID-19 pandemic, we noticed that between pizza boxes and online
                  orders we were starting to pile up more cardboard than the city could take every week. We
                  asked our neighbors, and they told us the same thing – too much cardboard! We knew we had
                  to find a solution."
                  text2="there is text"
                  text3="there is text"
                  text4="there is text"
                ></ImageText>
              </div>
            </Shape>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: props.isMobile ? "column" : "row",
            position: "relative",
            justifyContent: "space-between",
            marginBottom: "60px",
            paddingLeft: "5%",
            alignItems: "center",
          }}
        >
          <AboutDetails name={"Shaiv Kamat"} imageSrc={ShaivImg} />
          <AboutDetails name={"Griffin Hay"} imageSrc={GriffinImg} />
          <AboutDetails name={"Wyatt Steinberg"} imageSrc={WyattImg} />
          <AboutDetails name={"Justin Chang"} imageSrc={JustinImg} />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          width: "100%",
        }}
      >
        <BottomHeader isMobile={props.isMobile} />
      </div>
    </Container>
  );
}

export default AboutUs;
