import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import StepConnector from "@material-ui/core/StepConnector";
// import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import InfoStage from "./Book_OTP_InfoStage";
import TimeStage from "./Book_OTP_TimeStage";
import Checkout from "./Book_OTP_Checkout";
import {
  Alert,
  Form,
  FormInput,
  FormGroup,
  FormTextarea,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "shards-react";
import BottomHeader from "../components/BottomHeader";
import { Image } from "react-bootstrap";
import headerImage from "../res/Contact-us/sub-header.jpg";

var validator = require("email-validator");
var blocked = true;
const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Enter address & Contact info", "Pick your pickup time", "Checkout"];
}

function getStepContent(
  step,
  setAddress,
  setPlaceId,
  setContactValue,
  setContactType,
  setDistanceFromOrigin,
  getInitial,
  setPickupDate,
  setPickupTime,
  setJob
) {
  switch (step) {
    case 0:
      return (
        <InfoStage
          setAddress={setAddress}
          setPlaceId={setPlaceId}
          setContactValue={setContactValue}
          setContactType={setContactType}
          setDistanceFromOrigin={setDistanceFromOrigin}
          getInitial={getInitial}
        />
      );
    case 1:
      return (
        <TimeStage
          setPickupDate={setPickupDate}
          setPickupTime={setPickupTime}
          setJob={setJob}
          getInitial={getInitial}
        />
      );
    case 2:
      return <Checkout getInitial={getInitial} />;
    default:
      return "Unknown step";
  }
}

export default function CustomizedSteppers(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [address, setAddress] = React.useState("");
  const [placeId, setPlaceId] = React.useState("");
  const [contactValue, setContactValue] = React.useState("");
  const [contactType, setContactType] = React.useState("");
  const [distanceFromOrigin, setDistanceFromOrigin] = React.useState(0);
  const [pickupDate, setPickupDate] = React.useState("");
  const [job, setJob] = React.useState({});
  const [pickupTime, setPickupTime] = React.useState("");

  const [emailError, showEmailError] = React.useState(false);
  const [phoneError, showPhoneError] = React.useState(false);
  const [dropDownError, showDropDownError] = React.useState(false);
  const [addressError, showAddressError] = React.useState(false);
  const [pickupDateError, showPickupDateError] = React.useState(false);
  const [pickupTimeError, showPickupTimeError] = React.useState(false);
  const steps = getSteps();

  const handleNext = () => {
    let invalid = false;

    if (activeStep === 0) {
      if (contactType === "email") {
        let valid = validator.validate(contactValue);
        if (!valid) {
          showEmailError(true);
          invalid = true;
        }
      } else if (contactType === "phone") {
        let valid = contactValue.length > 9 && contactValue.length < 15;
        if (!valid) {
          showPhoneError(true);
          invalid = true;
        }
      } else {
        showDropDownError(true);
        invalid = true;
      }
      let valid = placeId.length > 2;
      if (!valid) {
        showAddressError(true);
        invalid = true;
      }
    } else if (activeStep === 1) {
      const datesAreOnSameDay = (first, second) =>
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate();
      if (
        pickupDate === "" ||
        datesAreOnSameDay(new Date(pickupDate), new Date())
      ) {
        showPickupDateError(true);
        invalid = true;
      }
      if (pickupTime === "") {
        showPickupTimeError(true);
        invalid = true;
      }
    }
    if (invalid) return;

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function getInitial(stage) {
    if (stage === 1) {
      if (
        contactValue.length > 3 ||
        distanceFromOrigin !== 0 ||
        placeId.length > 2
      ) {
        return {
          contactType,
          contactValue,
          distanceFromOrigin,
          placeId,
          address,
        };
      } else {
        return false;
      }
    } else if (stage === 2) {
      if (pickupDate !== "" || pickupTime !== "") {
        return { pickupDate, pickupTime, job };
      } else return false;
    } else if (stage === 3) {
      return {
        contactType,
        contactValue,
        distanceFromOrigin,
        placeId,
        address,
        pickupDate,
        pickupTime,
        job,
      };
    }
  }

  return (
    <Container
      style={{
        position: "absolute",
        top: 0,
        left: -20,
        maxWidth: "220%",
        justifyContent: "center",
        alignSelf: "center",
      }}
      className="dr-example-container"
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
        }}
      >
        <Image
          style={{
            width: "100%",
          }}
          fluid
          src={headerImage}
        ></Image>
      </div>
      \
      <div
        style={{
          width: "100%",
          position: "absolute",
          top: "15vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            fontWeight: "1000",
            fontSize: props.isMobile ? "5vw" : "3vw",
            color: "rgb(255,255,255)",
          }}
        >
          BOOK ONE TIME PICKUP
        </h1>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: props.isMobile ? "40%" : "30%",
        }}
      >
        {activeStep === activeStep ? ( //steps.length
          <div>
            {/* DISAHBLED HERE */}
            <Typography className={classes.instructions}>
              We appreciate your interest! We're working on scaling up our infrastructure and will reopen asap
              {/* All steps completed - you&apos;re finished */}
            </Typography>
            {/* <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button> */}
          </div>
        ) : (
          <div
            style={{
              justifyContent: "Space-around",
              alignItems: "center",
              width: "70%",
            }}
          >
            <Card>
              <CardBody>
                <CardTitle>One Time Pickup</CardTitle>
              </CardBody>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<QontoConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={QontoStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <CardBody>
                <Alert
                  dismissible={() => {
                    showEmailError(false);
                  }}
                  open={emailError}
                >
                  Please enter in a valid <strong>Email</strong> address
                </Alert>

                <Alert
                  dismissible={() => {
                    showPhoneError(false);
                  }}
                  open={phoneError}
                >
                  Please enter in a valid <strong>Phone</strong> number
                </Alert>

                <Alert
                  dismissible={() => {
                    showAddressError(false);
                  }}
                  open={addressError}
                >
                  Please enter in a valid <strong>Pickup Address</strong>{" "}
                </Alert>

                <Alert
                  dismissible={() => {
                    showDropDownError(false);
                  }}
                  open={dropDownError}
                >
                  Please select a <strong>Method of Contact</strong>{" "}
                </Alert>

                <Alert
                  dismissible={() => {
                    showPickupDateError(false);
                  }}
                  open={pickupDateError}
                >
                  Please select a <strong>Date</strong> for pickup
                </Alert>

                <Alert
                  dismissible={() => {
                    showPickupTimeError(false);
                  }}
                  open={pickupTimeError}
                >
                  Please select a <strong>Time</strong> for pickup
                </Alert>

                <Row
                  style={{
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  {getStepContent(
                    activeStep,
                    setAddress,
                    setPlaceId,
                    setContactValue,
                    setContactType,
                    setDistanceFromOrigin,
                    getInitial,
                    setPickupDate,
                    setPickupTime,
                    setJob
                  )}
                </Row>

                <CardBody
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Row
                    style={{
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    <Button
                      disabled={activeStep === steps.length - 1}
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      Next
                    </Button>
                  </Row>
                </CardBody>
              </CardBody>
            </Card>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          paddingTop: "30%",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "relative",
            justifyContent: "space-between",
            marginBottom: "60px",
            paddingLeft: "5%",
            // paddingTop: "20%",
          }}
        ></div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          width: "100%",
        }}
      >
        <BottomHeader isMobile={props.isMobile} />
      </div>
    </Container>
  );
}
