import React from "react";
import { Button } from "../../components/AuthForm";
import { useAuth } from "../../context/auth";
import { useMachineValue } from "../../machines";

function Admin(props) {
  const [{ context }, send] = useMachineValue();

  function logOut() {
    send("LOGOUT");
  }

  return (
    <div>
      <div>Admin Page</div>
      <Button onClick={logOut}>Log out</Button>
    </div>
  );
}

export default Admin;
