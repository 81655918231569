import { assign, Machine, sendParent } from "xstate";
import utils from "util";

export const PollingMaching = Machine(
  {
    id: "PollingMaching",
    initial: "active",
    context: {
      accLvl: null,
    },
    states: {
      active: {
        invoke: {
          src: "subscribe",
        },
        on: {
          SET_TIMER: {
            actions: [
              assign({
                timerObject: (_, event) => {
                  return event.data;
                },
              }),
            ],
          },
        },
        onError: {
          target: "willRetry",
          actions: console.log,
        },
      },
      willRetry: {
        after: { 5000: "active" },
      },
    },
  },
  {
    services: {
      subscribe: (_, event) => {
        return (callback) => {
          async function gameTick() {
            function getUpdatedData(callback) {
              const req = {
                FunctionName: "getUpdatedData",
              };
            }

            const cloudScript = utils.promisify(getUpdatedData);
            const data = await cloudScript();
          }

          const timerObj = setInterval(gameTick, _.accLvl === 2 ? 1500 : 5000);
        };
      },
    },
  }
);
