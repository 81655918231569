import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Backendless from "backendless";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

// pick a date util library
import DayJsUtils from "@date-io/dayjs";
Backendless.initApp(
  "FCA9F083-7E4C-5555-FF8A-50897ED95B00",
  "7DD76079-D6FC-4CA3-A5C4-6556C7768783"
);

ReactDOM.render(
  <React.StrictMode>
    <MuiPickersUtilsProvider utils={DayJsUtils}>
      <App />
    </MuiPickersUtilsProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
