import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import StepConnector from "@material-ui/core/StepConnector";
import Typography from "@material-ui/core/Typography";
import InfoStage from "./SignUp_AccInfo";
import Checkout from "./SignUp_Checkout";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { Link } from "react-router-dom";
import {
  Alert,
  Form,
  FormInput,
  FormGroup,
  FormTextarea,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "shards-react";
import BottomHeader from "../components/BottomHeader";
import { Image } from "react-bootstrap";
import headerImage from "../res/Contact-us/sub-header.jpg";

var validator = require("email-validator");

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Account Details", "Set up your payment"];
}

function getStepContent(
  step,
  setAddress,
  setPlaceId,
  setEmail,
  setPassword,
  setPhone,
  setDistanceFromOrigin,
  getInitial
) {
  switch (step) {
    case 0:
      return (
        <InfoStage
          setAddress={setAddress}
          setPlaceId={setPlaceId}
          setEmail={setEmail}
          setPassword={setPassword}
          setPhone={setPhone}
          setDistanceFromOrigin={setDistanceFromOrigin}
          getInitial={getInitial}
        />
      );
    case 1:
      return <Checkout getInitial={getInitial} />;
    default:
      return "Unknown step";
  }
}

export default function Signup(props) {
  // const stripePromise = loadStripe(
  //   "pk_test_51HQTtsD3bne3Hf8rzE1EsgCVLETQz4JAQg9hNLByFMXR0Rp66vXTWdNzo0Vfy83rZnoGwL4kW4sD6MfRvl041vOC003OxpSnYf"
  // );
  const stripePromise = loadStripe(
    "pk_live_51HQTtsD3bne3Hf8rb6EC1SWOEo3lhfKPnTBHIYaZMpqYOVTITLwqAanrR5gRIwSL1pbnQmO4J3qFewsOsdWwpEBj00i85fr8YU"
  );
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [address, setAddress] = React.useState("");
  const [placeId, setPlaceId] = React.useState("");
  const [distanceFromOrigin, setDistanceFromOrigin] = React.useState(0);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [phone, setPhone] = React.useState("");

  const [emailError, showEmailError] = React.useState(false);
  const [phoneError, showPhoneError] = React.useState(false);
  const [addressError, showAddressError] = React.useState(false);
  const [passwordError, showPasswordError] = React.useState(false);
  const steps = getSteps();

  const handleNext = () => {
    let invalid = false;

    if (activeStep === 0) {
      let valid = validator.validate(email);
      if (!valid) {
        showEmailError(true);
        invalid = true;
      }
      if (phone && phone.length > 0) {
        valid = phone.length > 9 && phone.length < 15;
        if (!valid) {
          showPhoneError(true);
          invalid = true;
        }
      }
      valid = placeId.length > 2;
      if (!valid) {
        showAddressError(true);
        invalid = true;
      }
      valid = password.length > 0;
      if (!valid) {
        showPasswordError(true);
        invalid = true;
      }
    }
    if (invalid) return;

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function getInitial() {
    return {
      email,
      phone,
      password,
      distanceFromOrigin,
      placeId,
      address,
    };
  }

  return (
    <Container
      style={{
        position: "absolute",
        top: 0,
        left: -20,
        maxWidth: "220%",
        justifyContent: "center",
        alignSelf: "center",
      }}
      className="dr-example-container"
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
        }}
      >
        <Image
          style={{
            width: "100%",
          }}
          fluid
          src={headerImage}
        ></Image>
      </div>
      <div
        style={{
          width: "100%",
          position: "absolute",
          top: "15vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            fontWeight: "1000",
            fontSize: props.isMobile ? "5vw" : "3vw",
            color: "rgb(255,255,255)",
          }}
        >
          SIGN UP
        </h1>
      </div>
      <div
        style={{
          width: "100%",
          top: "30%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "30%",
        }}
      >
        {activeStep === activeStep ? ( //steps.length
          <div>
            <Typography className={classes.instructions}>
            We appreciate your interest! We're working on scaling up our infrastructure and will reopen asap
              {/* All steps completed - you&apos;re finished */}
            </Typography>
            {/* <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button> */}
          </div>
        ) : (
          <div
            style={{
              justifyContent: "Space-around",
              alignItems: "center",
              width: "70%",
              paddingTop: "5%",
            }}
          >
            <Card>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<QontoConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={QontoStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <CardBody>
                <Alert
                  dismissible={() => {
                    showEmailError(false);
                  }}
                  open={emailError}
                >
                  Please enter in a valid <strong>Email</strong> address
                </Alert>
                <Alert
                  dismissible={() => {
                    showPasswordError(false);
                  }}
                  open={passwordError}
                >
                  Please enter in a valid <strong>Password</strong>
                </Alert>

                <Alert
                  dismissible={() => {
                    showPhoneError(false);
                  }}
                  open={phoneError}
                >
                  Please enter in a valid <strong>Phone</strong> number
                </Alert>

                <Alert
                  dismissible={() => {
                    showAddressError(false);
                  }}
                  open={addressError}
                >
                  Please enter in a valid <strong>Pickup Address</strong>{" "}
                </Alert>

                <Row
                  style={{
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <Elements stripe={stripePromise}>
                    {getStepContent(
                      activeStep,
                      setAddress,
                      setPlaceId,
                      setEmail,
                      setPassword,
                      setPhone,
                      setDistanceFromOrigin,
                      getInitial
                    )}
                  </Elements>
                </Row>

                <CardBody
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Row
                    style={{
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    <Button
                      disabled={activeStep === steps.length - 1}
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      Next
                    </Button>
                  </Row>
                </CardBody>
              </CardBody>
              <Link to="/login">Already have an account?</Link>
            </Card>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          paddingTop: "5%",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "relative",
            justifyContent: "space-between",
            paddingLeft: "5%",
            // paddingTop: "20%",
          }}
        ></div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          width: "100%",
        }}
      >
        <BottomHeader isMobile={props.isMobile} />
      </div>
    </Container>
  );
}
