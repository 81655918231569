import { assign, Machine, send } from "xstate";
import { PollingMaching } from "./pollingMaching";

const tryAuth = async () => {
  console.log("not logged in");
  // send('LOGIN', {
  //   data: {}
  // });
  // return { playerId: response.data.PlayFabId, teamId: "SomeTeamId" };
};

export const AppMachine = Machine({
  id: "app",
  initial: "loggedOut",
  context: {
    user: null,
  },
  onEntry: {
    actions: [],
  },
  states: {
    loggedOut: {
      on: {
        LOGIN_CHECK: "authCheck",
        LOGIN: {
          target: "loggedIn",
          actions: [assign({ user: (context, event) => event.data })],
        },
      },
    },
    authCheck: {
      invoke: {
        src: (_) => tryAuth,
        onDone: {
          actions: [assign({})],
        },
        onError: {
          target: "loggedOut",
          actions: assign({ authChecked: (_) => true }),
        },
      },
      on: {
        LOGIN_FAILED: {
          target: "loggedOut",
          actions: assign({ authChecked: (_) => true }),
        },
      },
    },
    authenticate: {
      on: {
        AUTH: {
          target: "syncing",
          actions: assign({ playerId: (_, event) => event.data }),
        },
      },
    },
    syncing: {
      on: {
        LOGIN_FAILED: {
          target: "loggedOut",
          actions: assign({ authChecked: (_) => true }),
        },
      },
    },
    loggedIn: {
      on: {
        UPDATE_USER: {
          actions: assign({ user: (context, event) => event.data }),
        },
      },
      invoke: [
        // {
        //   id: "PollingMaching",
        //   src: PollingMaching,
        //   data: {},
        // },
      ],
      on: {
        LOGOUT: {
          actions: [
            assign({
              user: (_, event) => null,
            }),
          ],
        },
        SET_ONBOARDED: {
          actions: [
            assign({
              isOnboarded: (_, event) => {
                // This is a promise we're not awaiting 😈. Not good.
                return true;
              },
            }),
          ],
        },
      },
    },
    loggingOut: {
      invoke: {
        src: "LogOutMachine",
        data: {
          playerData: (context, _) => context.playerData,
          playerId: (context, _) => context.playerId,
        },
        onDone: {
          target: "loggedOut",
          actions: assign({
            authChecked: false,
            loggingOut: true,
          }),
        },
      },
    },
  },
});
