import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import PrivateRoute from "../PrivateRoute";
import Home from "../pages/HomePage";
import Book from "../pages/Book_OTP";
import Admin from "../pages/admin/Admin";
import Dashboard from "../pages/admin/Dashboard";
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import Success from "../pages/Success";
import CardboardServices from "../pages/CardboardServices";
import Mushrooms from "../pages/Mushroom";
import AboutUs from "../pages/AboutUs";
import Pricing from "../pages/Pricing";
import ContactUs from "../pages/ContactUs";
import Cancel from "../pages/Cancel";
import { AuthContext, useAuth } from "../context/auth";
import { useMachineValue } from "../machines";
import { Image } from "react-bootstrap";
import BottomHeader from "../components/BottomHeader";
import logYSprouts from "../res/landing-page/banner-imagethrd.jpg";
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  Collapse,
  Button,
  Container,
} from "shards-react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

function Navigator(props) {
  const [{ context }, send] = useMachineValue();
  const [authTokens, setAuthTokens] = useState(localStorage.getItem("tokens"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const size = useWindowSize();
  const classes = useStyles();
  // let isMobile = size.width <= 770;
  let collapseMenu = isMobile || size.width <= 810;
  // console.log(
  //   "Display Data: " + window.devicePixelRatio + "  isMobile: " + isMobile
  // );

  // switch(window.devicePixelRatio){

  // }

  // const setTokens = (data) => {
  //   localStorage.setItem("tokens", JSON.stringify(data));
  //   setAuthTokens(data);
  // };

  function logOut() {
    send("LOGOUT");
  }
  let logBtn = context.user === null;
  return (
    // <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
    <Router>
      <div
        style={{
          width: "100%",
          height: "1000px",
          background: "#E2F2F0",
        }}
      ></div>
      <Route
        exact
        path="/"
        render={(props) => <Home {...props} isMobile={isMobile} />}
      />
      <Route
        exact
        path="/contact"
        render={(props) => <ContactUs {...props} isMobile={isMobile} />}
      />
      <Route
        exact
        path="/aboutUs"
        render={(props) => <AboutUs {...props} isMobile={isMobile} />}
      />
      <Route
        exact
        path="/cardboardServices"
        render={(props) => <CardboardServices {...props} isMobile={isMobile} />}
      />
      <Route
        exact
        path="/mushrooms"
        render={(props) => <Mushrooms {...props} isMobile={isMobile} />}
      />

      <Route
        exact
        path="/book"
        render={(props) => <Book {...props} isMobile={isMobile} />}
      />
      <Route
        exact
        path="/success_otp"
        render={(props) => <Success {...props} isMobile={isMobile} />}
      />
      <Route
        exact
        path="/cancel_otp"
        render={(props) => <Cancel {...props} isMobile={isMobile} />}
      />
      <Route
        exact
        path="/login"
        render={(props) => <Login {...props} isMobile={isMobile} />}
      />
      <Route
        exact
        path="/signup"
        render={(props) => <Signup {...props} isMobile={isMobile} />}
      />
      {/* <Route path="/aboutUs" component={AboutUs} />
      <Route path="/pricing" component={Pricing} /> */}

      <PrivateRoute path="/dashboard" component={Dashboard} />

      {/* <PrivateRoute path="/admin" component={Admin} /> */}

      <div
        style={
          {
            // width: "100%",
          }
        }
      >
        {/* <ul>
            <li>
              <Link to="/">Home Page</Link>
            </li>
            <li>
              <Link to="/admin">Admin Page</Link>
            </li>
          </ul> */}
        {/* <Image
          style={{
            width: "100%",
          }}
          fluid
          src={logYSprouts}
        ></Image> */}
        {/* <Nav navbar className="ml-auto">
            <Link to="/pricing">
              <NavLink active>Pricing</NavLink>
            </Link>
          </Nav>

          <Nav navbar className="ml-auto">
            <Link to="/aboutUs">
              <NavLink active>About Us</NavLink>
            </Link>
          </Nav> */}

        <Navbar
          style={{
            marginBottom: 75,
            position: "absolute",
            top: "1%",
            width: "100%",
            display: "flex",
            "flex-direction": "row",
            "justify-content": "space-between",
            // left: "50%",
          }}
          type="dark"
          theme="rgba(0,0,0,0.0)"
          expand="md"
        >
          <div>
            <Link
              to="/"
              style={{
                justifyContent: "flex-start",
              }}
            >
              <NavbarBrand>TakeIt Recycling</NavbarBrand>
            </Link>
          </div>
          {!collapseMenu && (
            <div>
              <Nav navbar className="ml-auto">
                <Nav navbar className="ml-auto">
                  <Link to="/">
                    <NavLink active>Home</NavLink>
                  </Link>
                </Nav>
                <Nav navbar className="ml-auto">
                  {!logBtn && (
                    <Link to="/Dashboard">
                      <NavLink active>Dashboard</NavLink>
                    </Link>
                  )}
                </Nav>
                <Nav navbar className="ml-auto">
                  <Link to="/cardboardServices">
                    <NavLink active>Cardboard Services</NavLink>
                  </Link>
                </Nav>
                <Nav navbar className="ml-auto">
                  <Link to="/mushrooms">
                    <NavLink active>Mushrooms</NavLink>
                  </Link>
                </Nav>
                <Nav navbar className="ml-auto">
                  <Link to="/aboutUs">
                    <NavLink active>About</NavLink>
                  </Link>
                </Nav>
                <Nav navbar className="ml-auto">
                  <Link to="/contact">
                    <NavLink active>Contact</NavLink>
                  </Link>
                </Nav>
              </Nav>
            </div>
          )}

          {!collapseMenu && (
            <div>
              <Nav navbar className="ml-auto">
                {logBtn && window.location.pathname !== "/login" && (
                  <Link to="/login">
                    <NavLink active>Login</NavLink>
                  </Link>
                )}
                {logBtn && window.location.pathname !== "/signup" && (
                  <Link to="/signup">
                    <NavLink active>SignUp</NavLink>
                  </Link>
                )}
                {!logBtn && (
                  <NavLink active onClick={logOut}>
                    LogOut
                  </NavLink>
                )}
              </Nav>
            </div>
          )}
          {collapseMenu && (
            <div>
              <IconButton
                edge="start"
                className={classes.menuButton}
                aria-label="menu"
                aria-haspopup="true"
                onClick={handleClick}
                style={{
                  color: "white",
                }}
              >
                <MenuIcon />
              </IconButton>

              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                // PaperProps={{
                //   style: {
                //     maxHeight: ITEM_HEIGHT * 4.5,
                //     width: '20ch',
                //   },
                // }}
              >
                <Link to="/">
                  <MenuItem onClick={handleClose}>Home</MenuItem>
                </Link>
                <Link to="/cardboardServices">
                  <MenuItem onClick={handleClose}>Cardboard Service</MenuItem>
                </Link>
                <Link to="/mushrooms">
                  <MenuItem onClick={handleClose}>Mushrooms</MenuItem>
                </Link>
                <Link to="/aboutUs">
                  <MenuItem onClick={handleClose}>About</MenuItem>
                </Link>
                <Link to="/contact">
                  <MenuItem onClick={handleClose}>Contact</MenuItem>
                </Link>
                {/* <Link to="/login">
                  <MenuItem onClick={handleClose}>Login</MenuItem>
                </Link>
                <Link to="/signup">
                  <MenuItem onClick={handleClose}>Signup</MenuItem>
                </Link> */}
                {logBtn && window.location.pathname !== "/login" && (
                  <Link to="/login">
                    <MenuItem onClick={handleClose}>Login</MenuItem>
                  </Link>
                )}
                {logBtn && window.location.pathname !== "/signup" && (
                  <Link to="/signup">
                    <MenuItem onClick={handleClose}>Signup</MenuItem>
                  </Link>
                )}
                {!logBtn && (
                  <Link to="/Dashboard">
                    <MenuItem onClick={handleClose}>Dashboard</MenuItem>
                  </Link>
                )}
                {!logBtn && (
                  <Link to="/signup">
                    <MenuItem onClick={logOut}>LogOut</MenuItem>
                  </Link>
                )}
              </Menu>
            </div>
          )}
        </Navbar>
      </div>
    </Router>
  );
}

export default Navigator;
