import React, { useState } from "react";
import { Buttons } from "../../components/AuthForm";
import { useAuth } from "../../context/auth";
import { useMachineValue } from "../../machines";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import Backendless from "backendless";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import axios from "axios";
import {
  Button,
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormInput,
  FormGroup,
} from "shards-react";
import BottomHeader from "../../components/BottomHeader";
import { Image } from "react-bootstrap";
import headerImage from "../../res/Contact-us/sub-header.jpg";
import Scheduler from "./Scheduler";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
const testPriceid = "price_1HV4e1D3bne3Hf8rJz8f0HqJ";
const prodPriceId = "price_1HV4d9D3bne3Hf8rIMQtogtL";
const DISTANCE_RANGES = { firstBound: 4.5, secondBound: 9, thirdBound: 15 }; //km

function Dashboard(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [showStripeError, setShowStripeError] = useState(false);
  const [showCancelQuestion, setShowCancelQuestion] = useState(false);
  const [showEditPayement, setShowEditPayement] = useState(false);
  const [showAddSubscription, setShowAddSubscription] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [{ context }, send] = useMachineValue();
  const { user } = context;
  // console.log(user);
  let subscription = null;
  if (user.subscription) subscription = user.subscription;

  function toggle() {
    setShowCancelQuestion(!showCancelQuestion);
  }

  function togglePayement() {
    setShowEditPayement(!showEditPayement);
  }

  function toggleSubscription() {
    setShowAddSubscription(!showAddSubscription);
  }
  function logOut() {
    send("LOGOUT");
  }

  function cancelSubscription(cancelWhen) {
    axios
      .post(
        `https://api.backendless.com/FCA9F083-7E4C-5555-FF8A-50897ED95B00/7DD76079-D6FC-4CA3-A5C4-6556C7768783/services/StripeService/cancelSubscription`,
        {
          id: subscription.subscription.id,
          cancelNow: cancelWhen,
        }
      )
      .then(async (res) => {
        console.log(res);
        let updatedUser = user;
        updatedUser.subscription.subscription = res;
        updatedUser.subscriptionStatus = "canceled";

        console.log(user);
        Backendless.UserService.update(user)
          .then(function (newUser) {
            console.log(newUser);
            send("UPDATE_USER", { data: newUser });
            toggle();
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  let cardContent = null;
  if (user.subscriptionStatus === "succeeded") {
    cardContent = (
      <Card>
        <CardBody>
          <CardTitle>Monthly Pickup Service</CardTitle>

          <CardBody
            style={{
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <CardSubtitle>
              Status:{" "}
              {subscription.subscription.latest_invoice.payment_intent.status}
            </CardSubtitle>
          </CardBody>

          <CardBody
            style={{
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <CardSubtitle>
              Price: $
              {subscription.subscription.latest_invoice.amount_due / 100} CAD
            </CardSubtitle>
          </CardBody>

          <CardBody
            style={{
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <CardSubtitle>
              Next Charge Date:
              {new Date(
                subscription.subscription.current_period_end * 1000
              ).toString()}{" "}
            </CardSubtitle>
          </CardBody>

          <CardBody
            style={{
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Button size="lg" onClick={toggle}>
              Cancel Subscription
            </Button>
            <Button size="lg" onClick={togglePayement}>
              Edit Payment Method
            </Button>
          </CardBody>
        </CardBody>
      </Card>
    );
  } else {
    cardContent = (
      <Card>
        <CardBody>
          <CardTitle>No Active Subscription</CardTitle>

          <CardBody
            style={{
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Button size="lg" onClick={toggleSubscription}>
              Start Subscription
            </Button>
          </CardBody>
        </CardBody>
      </Card>
    );
  }

  function onSubscriptionComplete(result) {
    console.log(result);
    // Payment was successful. Provision access to your service.
    // Remove invoice from localstorage because payment is now complete.
    // clearCache();
    if (result && !result.subscription) {
      const subscription = { id: result.invoice.subscription };
      result.subscription = subscription;
      localStorage.clear();
    }
    let updatedUser = user;
    updatedUser.subscription = result;
    updatedUser.subscriptionStatus =
      result.subscription.latest_invoice.payment_intent.status;

    console.log(user);
    Backendless.UserService.update(user)
      .then(function (newUser) {
        console.log(newUser);
        send("UPDATE_USER", { data: newUser });
        if (showEditPayement) {
          togglePayement();
        } else if (showAddSubscription) {
          toggleSubscription();
        }
        setSubmitting(false);
      })
      .catch(function (error) {
        console.log(error);
      });
    // Change your UI to show a success message to your customer.
    // onSubscriptionSampleDemoComplete(result);
    // Call your backend to grant access to your service based on
    // the product your customer subscribed to.
    // Get the product by using result.subscription.price.product
  }

  function handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId,
  }) {
    if (subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      "requires_payment_method"
    ) {
      setShowStripeError(true);
      throw new Error(
        "There was an issue processing your information, please double check and contact us at shaivkamat@gmail.com"
      );
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) {
    if (subscription && subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }
    setShowStripeError(true);
    throw new Error(
      "There was an issue processing your information, please double check and contact us at shaivkamat@gmail.com"
    );
  }

  function editSubscription({ paymentMethodId }) {
    const priceId = prodPriceId;
    return (
      axios
        .post(
          `https://api.backendless.com/FCA9F083-7E4C-5555-FF8A-50897ED95B00/7DD76079-D6FC-4CA3-A5C4-6556C7768783/services/StripeService/editSubscription`,
          {
            customerId: user.customerId,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            subscriptionId: subscription.subscription.id,
          }
        )
        .then((response) => {
          console.log(response);
          return response;
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned
        // by Stripe. Add the addional details we need.
        .then((result) => {
          console.log(result);
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            subscription: result.data,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
          };
        })
        // Some payment methods require a customer to do additional
        // authentication with their financial institution.
        // Eg: 2FA for cards.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail. You will
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          setSubmitting(false);
          console.log(error);
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          // setSubscribing(false);
          // setErrorToDisplay(error.message || error.error.decline_code);
        })
    );
  }

  function createSubscription({ paymentMethodId }) {
    const priceId = prodPriceId;
    let distanceZone = ""; // 0 = no fee, 1 = $5, 2 = $10

    if (user.distanceFromOrigin <= DISTANCE_RANGES.firstBound) {
      distanceZone = "0";
    } else if (user.distanceFromOrigin <= DISTANCE_RANGES.secondBound) {
      distanceZone = "1";
    } else if (user.distanceFromOrigin <= DISTANCE_RANGES.thirdBound) {
      distanceZone = "2";
    }
    return (
      axios
        .post(
          `https://api.backendless.com/FCA9F083-7E4C-5555-FF8A-50897ED95B00/7DD76079-D6FC-4CA3-A5C4-6556C7768783/services/StripeService/createSubscription`,
          {
            customerId: user.customerId,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            deliveryString: distanceZone,
            promotionCode: "false",
          }
        )
        .then((response) => {
          console.log(response);
          return response;
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            setShowStripeError(true);
            // The card had an error when trying to attach it to a customer
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned
        // by Stripe. Add the addional details we need.
        .then((result) => {
          console.log(result);
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            subscription: result.data,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
          };
        })
        // Some payment methods require a customer to do additional
        // authentication with their financial institution.
        // Eg: 2FA for cards.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail. You will
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          setSubmitting(false);
          console.log(error);
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
        })
    );
  }

  const handleSubmit = async (event) => {
    setSubmitting(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    if (!elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    // If a previous payment was attempted, get the latest invoice
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    if (error) {
      setSubmitting(false);
      console.log("[createPaymentMethod error]", error);
    } else {
      console.log("[PaymentMethod]", paymentMethod);
      const paymentMethodId = paymentMethod.id;
      if (showEditPayement) {
        editSubscription({
          paymentMethodId: paymentMethodId,
        });
      } else if (showAddSubscription) {
        createSubscription({
          paymentMethodId: paymentMethodId,
        });
      }
    }
  };

  return (
    <Container
      style={{
        position: "absolute",
        top: 0,
        left: -20,
        maxWidth: "220%",
        justifyContent: "center",
        alignSelf: "center",
      }}
      className="dr-example-container"
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
        }}
      >
        <Image
          style={{
            width: "100%",
          }}
          fluid
          src={headerImage}
        ></Image>
      </div>
      <div
        style={{
          width: "100%",
          position: "absolute",
          top: "15em",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            fontWeight: "1000",
            fontSize: props.isMobile ? "5vw" : "3vw",
            color: "rgb(255,255,255)",
          }}
        >
          DASHBOARD
        </h1>
      </div>
      <div>
        <span
          style={{
            color: "#2c4058",
            fontWeight: "800",
            fontSize: props.isMobile ? "4vw" : "2vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: props.isMobile ? "35%" : "30%",
          }}
        >
          SUBSCRIPTIONS
        </span>
      </div>
      <div
        style={{
          justifyContent: "Space-around",
          alignItems: "center",
          width: 400,
        }}
      ></div>
      <div
        style={{
          justifyContent: "Space-around",
          alignItems: "center",
        }}
      >
        {cardContent}
      </div>

      <div
        style={{
          display: user && user.role === "admin" ? "block" : "none",
        }}
      >
        <Scheduler />
      </div>

      <Modal open={showEditPayement} toggle={togglePayement}>
        <Alert
          dismissible={() => {
            setShowStripeError(false);
          }}
          theme="warning"
          open={showStripeError}
        >
          There was an issue processing your payments, please double check your
          info. Email us at <strong>shaivkamat@TakeItRecycling.ca</strong> for
          assistance
        </Alert>
        <ModalHeader>Edit Payment Method</ModalHeader>
        <ModalBody>
          <Col>
            <label>Card details</label>
            <CardElement options={CARD_ELEMENT_OPTIONS} />
            <Form>
              <FormGroup>
                <Button
                  pill
                  disabled={submitting}
                  theme="success"
                  onClick={handleSubmit}
                >
                  Checkout
                </Button>
              </FormGroup>
            </Form>
          </Col>
        </ModalBody>
      </Modal>

      <Modal open={showAddSubscription} toggle={toggleSubscription}>
        <Alert
          dismissible={() => {
            setShowStripeError(false);
          }}
          theme="warning"
          open={showStripeError}
        >
          There was an issue processing your payments, please double check your
          info. Email us at <strong>shaivkamat@gmail.com</strong> for assistance
        </Alert>
        <ModalHeader>Create Subscription</ModalHeader>
        <ModalBody>
          <Col>
            <label>Card details</label>
            <CardElement options={CARD_ELEMENT_OPTIONS} />
            <Form>
              <FormGroup>
                <Button
                  pill
                  disabled={submitting}
                  theme="success"
                  onClick={handleSubmit}
                >
                  Checkout
                </Button>
              </FormGroup>
            </Form>
          </Col>
        </ModalBody>
      </Modal>

      <Modal open={showCancelQuestion} toggle={toggle}>
        <ModalHeader>Subscription Cancellation</ModalHeader>
        <ModalBody>
          👋 Hello there! Select how you would like your cancellation to go
          <Row
            style={{
              justifyContent: "Space-around",
              alignItems: "center",
            }}
          >
            <Button
              size="lg"
              onClick={() => {
                cancelSubscription("true");
              }}
              style={{
                marginBottom: 10,
              }}
            >
              Now
            </Button>
            <Button
              size="lg"
              onClick={() => {
                cancelSubscription("false");
              }}
            >
              End of Billing Period
            </Button>
          </Row>
        </ModalBody>
      </Modal>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          paddingTop: "5%",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "relative",
            justifyContent: "space-between",
            paddingLeft: "5%",
            // paddingTop: "20%",
          }}
        ></div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          width: "100%",
        }}
      >
        <BottomHeader isMobile={props.isMobile} />
      </div>
    </Container>
  );
}

export default Dashboard;
