import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { useMachine } from "@xstate/react";
import { HelmetProvider } from "react-helmet-async";
import { Button } from "shards-react";

import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";

// Navigator
import Navigator from "./navigation/AppNavigator";

// Game State
import { MachineContext, Machine } from "./machines";
import { SignupMachineContext, SignUpMachine } from "./machines/signupMachine";

const App = function App() {
  const [current, send] = useMachine(Machine);
  const machine = [current, send];

  // Signup is kind of different from the app machine. Instead of nesting
  // inside of 'loggedOut', we break it out here into it's own top-level
  // context that can track the navigation changes.
  const [signUpCurrent, signUpSend] = useMachine(SignUpMachine);
  const signUp = [signUpCurrent, signUpSend];

  return (
    <HelmetProvider>
      <MachineContext.Provider value={machine}>
        <SignupMachineContext.Provider value={signUp}>
          <Navigator />
        </SignupMachineContext.Provider>
      </MachineContext.Provider>
    </HelmetProvider>
  );
};

export default App;
