import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./context/auth";
import { useMachineValue } from "./machines";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

function PrivateRoute({ component: Component, ...rest }) {
  const [{ context }, send] = useMachineValue();
  // const stripePromise = loadStripe(
  //   "pk_test_51HQTtsD3bne3Hf8rzE1EsgCVLETQz4JAQg9hNLByFMXR0Rp66vXTWdNzo0Vfy83rZnoGwL4kW4sD6MfRvl041vOC003OxpSnYf"
  // );
  const stripePromise = loadStripe(
    "pk_live_51HQTtsD3bne3Hf8rb6EC1SWOEo3lhfKPnTBHIYaZMpqYOVTITLwqAanrR5gRIwSL1pbnQmO4J3qFewsOsdWwpEBj00i85fr8YU"
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        context.user ? (
          <Elements stripe={stripePromise}>
            <Component {...props} />
          </Elements>
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { referer: props.location } }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
