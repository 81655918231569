import { createContext, useContext } from "react";
import { assign, Machine as MachineLib } from "xstate";
import qs from "qs";

export const SignUpMachine = MachineLib({
  id: "SignupMachine",
  initial: "referralCheck",
  context: {
    error: null,
    referralId: null,
    referralTeamName: null,
  },
  states: {
    referralCheck: {
      invoke: {
        src: (event, context) => async (_) => {},
        onDone: {
          target: "idle",
          actions: assign({}),
        },
        onError: {
          target: "failure",
          actions: assign({ error: (context, event) => event.error }),
        },
      },
    },
    idle: {
      on: {},
    },
    success: {
      type: "final",
    },
    failure: {
      entry: (context, event) =>
        console.log("[SignupMachine] Error", context, event),
    },
  },
});

export const SignupMachineContext = createContext();
export const useSignUpValue = () => useContext(SignupMachineContext);
