import React from "react";
import {
  Form,
  FormInput,
  FormGroup,
  Alert,
  Col,
  FormSelect,
  FormRadio,
} from "shards-react";
import PhoneInput from "react-phone-number-input/input";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const DISTANCE_FLAG_KM = 20;
class Book_OTP_InfoStage extends React.Component {
  constructor(props) {
    super(props);
    this.dismiss = this.dismiss.bind(this);
    let initialVals = this.props.getInitial();
    if (!initialVals)
      this.state = {
        visible: false,
        address: "",
        placeId: "",
        gmapsLoaded: false,
        distanceFromOrigin: 0,
        email: "",
        phone: "",
        password: "",
        phoneSelected: false,
        passwordError: "",
      };
    else
      this.state = {
        visible: false,
        address: initialVals.address,
        placeId: initialVals.placeId,
        gmapsLoaded: false,
        distanceFromOrigin: initialVals.distanceFromOrigin,
        email: initialVals.email,
        phone: initialVals.phone ? initialVals.phone : "",
        password: initialVals.password,
        passwordError: "",
        phoneSelected: initialVals.phone ? initialVals.phone.length > 0 : false,
      };
  }

  initMap = () => {
    this.setState({
      gmapsLoaded: true,
    });
  };

  componentDidMount() {
    window.initMap = this.initMap;
    const gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAzMZnr9fed5nZBhqXCQjZbQTljErOGv0s&libraries=places&callback=initMap`;
    document
      .querySelector(`body`)
      .insertAdjacentElement(`beforeend`, gmapScriptEl);
  }
  getDistanceFromLatLonInKm = (lat2, lon2) => {
    function deg2rad(deg) {
      return deg * (Math.PI / 180);
    }
    var R = 6371; // Radius of the earth in km
    var origin = { lat: 49.270163, lon: -123.172084 };
    var dLat = deg2rad(lat2 - origin.lat); // deg2rad below
    var dLon = deg2rad(lon2 - origin.lon);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(origin.lat)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  };

  dismiss() {
    this.setState({ visible: false });
  }

  handlePasswordBlur = () => {
    if (this.state.password.length > 0) {
      if (this.state.password.length < 12) {
        const mediumRegex = new RegExp(
          "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})"
        );
        if (!mediumRegex.test(this.state.password)) {
          this.setState({ passwordError: true });
          this.props.setPassword("");
          return;
        }
      }
      this.props.setPassword(this.state.password);
      this.setState({ passwordError: false });
    }
  };

  selectPhone = (selected) => {
    this.setState({
      phoneSelected: selected,
    });
  };

  handleChange = (address) => {
    this.setState({ address, distanceFromOrigin: 0, placeId: "" });
  };

  handleEmailChange = (email) => {
    this.setState({ email: email.target.value });
    this.props.setEmail(email.target.value);
  };

  handlePasswordChange = (password) => {
    this.setState({ password: password.target.value });
  };

  handlePhoneChange = (phone) => {
    this.setState({ phone });
    this.props.setPhone(phone);
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        this.setState({
          placeId: results[0].place_id,
          address: results[0].formatted_address,
        });
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        let dist = this.getDistanceFromLatLonInKm(latLng.lat, latLng.lng);
        if (dist > DISTANCE_FLAG_KM) {
          this.setState({
            address: "",
            distanceFromOrigin: 0,
            placeId: "",
            visible: true,
          });
        } else {
          this.setState({ distanceFromOrigin: dist });
          console.log(
            "Success",
            latLng,
            this.getDistanceFromLatLonInKm(latLng.lat, latLng.lng)
          );
        }
      })
      .catch((error) => console.error("Error", error));
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        this.setState({
          placeId: results[0].place_id,
          address: results[0].formatted_address,
        });

        return getLatLng(results[0]);
      })
      .then((data) => {
        let dist = this.getDistanceFromLatLonInKm(data.lat, data.lng);
        console.log(JSON.stringify(this.state.address, null, 4));
        if (dist > DISTANCE_FLAG_KM) {
          this.setState({
            address: "",
            distanceFromOrigin: 0,
            placeId: "",
            visible: true,
          });
          this.props.setAddress("");
          this.props.setPlaceId("");
          this.props.setDistanceFromOrigin(0);
        } else {
          this.setState({ distanceFromOrigin: dist });

          this.props.setAddress(this.state.address);
          this.props.setPlaceId(this.state.placeId);
          this.props.setDistanceFromOrigin(dist);
          console.log(
            "Success",
            data,
            this.getDistanceFromLatLonInKm(data.lat, data.lng)
          );
        }
      })
      .catch((error) => console.error("Error", error));
  };

  render() {
    const google = window.google;
    return (
      <Form>
        <Alert dismissible={this.dismiss} open={this.state.visible}>
          Sorry, we only pick up in <strong> Metro Vancouver </strong>;
        </Alert>
        <FormGroup>
          {this.state.gmapsLoaded && (
            <div>
              <FormGroup>
                <label htmlFor="#email">Email</label>
                <FormInput
                  id="#email"
                  type="email"
                  placeholder="Email"
                  defaultValue={this.state.email}
                  onChange={this.handleEmailChange}
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor="#password">Password</label>
                <FormInput
                  type="password"
                  id="#password"
                  placeholder="Password"
                  defaultValue={this.state.password}
                  onChange={this.handlePasswordChange}
                  onBlur={this.handlePasswordBlur}
                />
                {this.state.passwordError && (
                  <div>
                    <div>
                      <label htmlFor="">
                        Passwords between 8 - 12 chars must have either:)
                      </label>
                    </div>
                    <div>
                      <label htmlFor="">Special Characters</label>
                    </div>
                    <div>
                      <label htmlFor="">Numbers</label>
                    </div>
                    <div>
                      <label htmlFor="">
                        **No character restrictions on passwords {">"} 12
                        characters
                      </label>
                    </div>
                  </div>
                )}
              </FormGroup>
              <label htmlFor="">Pickup Address</label>
              <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                searchOptions={{
                  origin: new google.maps.LatLng(49.250289, -123.031485),
                  location: new google.maps.LatLng(49.250289, -123.031485),
                  componentRestrictions: {
                    country: ["ca"],
                  },
                  radius: 20000,
                  types: ["address"],
                }}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <FormInput
                      {...getInputProps({
                        placeholder: "Search Places ...",
                        className: "location-search-input",
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
          )}
        </FormGroup>
        <div>
          <FormRadio
            inline
            name="sport"
            checked={this.state.phoneSelected}
            onChange={() => {
              this.setState({
                phoneSelected: !this.state.phoneSelected,
              });
            }}
          >
            Want us to send you text notifications about your pickup?
          </FormRadio>
        </div>
        <div>
          {this.state.phoneSelected && (
            <PhoneInput
              placeholder="Enter phone number"
              value={this.state.phone}
              onChange={this.handlePhoneChange}
              defaultCountry="CA"
            />
          )}
        </div>
      </Form>
    );
  }
}

export default Book_OTP_InfoStage;
