import React, { useState, useEffect } from "react";
import { Form, FormInput, FormSelect, FormGroup } from "shards-react";
import { KeyboardDatePicker, TimePicker } from "@material-ui/pickers";
import DayPicker, { DateUtils } from "react-day-picker";
import axios from "axios";

function Book_OTP_TimeStage(props) {
  const [dateArray, setDateArray] = useState([]);
  const [job, setJob] = useState({});
  const [disabledDateArray, setDisabledDateArray] = useState([]);
  const [selectedDate, handleDateChange] = useState(
    new Date().setDate(new Date().getDate())
  );
  const [selectedDateObject, setSelectedDateObject] = useState({});
  const [selectedTime, handleTimeChange] = useState("");

  useEffect(() => {
    let data = {
      jobDate: new Date().toString(),
      timeSlot: -1,
      type: "OTPpickup",
      tags: ["Driver"],
      opTags: ["Default"],
      employees: [],
      duration: 1, //timeslot
      hours: 1,
      overlappable: true,
      maxOverlap: 3,
    };
    setJob({ ...data });
    axios
      .post(
        `https://api.backendless.com/FCA9F083-7E4C-5555-FF8A-50897ED95B00/7DD76079-D6FC-4CA3-A5C4-6556C7768783/services/StripeService/getJobs`,
        data
      )
      .then((res) => {
        if (res.data) {
          let tempArr = [];
          for (let x = 0; x < res.data.length; x++) {
            let isDayValid = false; // day is valid if atleast 1 timeslot is valid
            let tempSlots = [...res.data[x].timeslots];
            isDayValid =
              tempSlots[0].currentJobData.isValid ||
              tempSlots[1].currentJobData.isValid ||
              tempSlots[2].currentJobData.isValid ||
              tempSlots[3].currentJobData.isValid;

            if (!isDayValid) {
              tempArr.push(
                new Date(
                  new Date(res.data[x].date).setDate(
                    new Date(res.data[x].date).getDate() // + 1
                  )
                )
              ); //TODO: Find out why converting to Date removes a day
            }
          }
          setDisabledDateArray([...tempArr]);
          setDateArray(res.data);
        }
      })
      .catch((res) => {
        console.log(JSON.stringify(res, null, 4));
      });
  }, []);

  const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

  const handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) return;
    handleDateChange({
      selectedDay: modifiers.selected ? undefined : new Date(day),
    });
    if (!modifiers.selected) {
      let tempJob = { ...job };
      tempJob.jobDate = new Date(day);
      setJob({ ...tempJob });
      props.setPickupDate(tempJob.jobDate);
      for (let x = 0; x < dateArray.length; x++) {
        if (datesAreOnSameDay(new Date(day), new Date(dateArray[x].date))) {
          // x -= 1; //TODO: Find out why converting to Date addes a day
          // console.log(dateArray[x]);
          setSelectedDateObject(dateArray[x]);
          break;
        }
      }
    }
  };
  let initialVals = props.getInitial(2);
  if (
    initialVals &&
    (initialVals.pickupTime !== selectedTime ||
      initialVals.pickupDate !== selectedDate)
  ) {
    handleDateChange(initialVals.pickupDate);
    handleTimeChange(initialVals.pickupTime);
  }

  return (
    <Form>
      <FormGroup>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {dateArray.length > 0 && (
            <DayPicker
              onDayClick={handleDayClick}
              selectedDays={selectedDate}
              disabledDays={[
                ...disabledDateArray,
                { daysOfWeek: [0, 6] },
                { before: new Date() },
                { after: new Date(dateArray[dateArray.length - 1].date) },
              ]}
            />
          )}
          <KeyboardDatePicker
            //refuse [regex expression]
            value={selectedDate}
            disablePast
            onChange={(event) => {
              handleDateChange(new Date(event));
              props.setPickupDate(new Date(event));
            }}
            label="Pickup Date"
            minDate={new Date().setDate(new Date().getDate() + 1)}
            disabled
            // showTodayButton
          />
        </div>
      </FormGroup>
      {Object.keys(selectedDateObject).length > 0 && (
        <FormGroup>
          <label htmlFor="#FormSelect">Pick Your 2-hour slot</label>
          <FormSelect
            size="lg"
            onChange={(event) => {
              let tempJob = { ...job };
              switch (event.target.value) {
                case "12":
                  tempJob.timeSlot = 0;
                  break;
                case "14":
                  tempJob.timeSlot = 1;
                  break;
                case "16":
                  tempJob.timeSlot = 2;
                  break;
                case "18":
                  tempJob.timeSlot = 3;
                  break;
                default:
                  break;
              }
              tempJob.employees =
                selectedDateObject.timeslots[
                  tempJob.timeSlot
                ].currentJobData.employees;
              setJob({ ...tempJob });
              props.setJob({ ...tempJob });
              console.log(tempJob);
              handleTimeChange(event.target.value);
              props.setPickupTime(event.target.value);
            }}
          >
            <option value="" selected>
              {" "}
              Select Pickup Slot{" "}
            </option>
            {selectedDateObject.timeslots[0].currentJobData.isValid && (
              <option value="12">12PM</option>
            )}
            {selectedDateObject.timeslots[1].currentJobData.isValid && (
              <option value="14">2PM</option>
            )}
            {selectedDateObject.timeslots[2].currentJobData.isValid && (
              <option value="16">4PM</option>
            )}
            {selectedDateObject.timeslots[3].currentJobData.isValid && (
              <option value="18">6PM</option>
            )}
          </FormSelect>
        </FormGroup>
      )}
    </Form>
  );
}

export default Book_OTP_TimeStage;
