import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "shards-react";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

function Pricing(props) {
  return (
    <Container className="dr-example-container">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 30,
          fontWeight: "500",
          paddingBottom: 100,
        }}
      >
        Pricing
      </div>
      <div
        style={{
          justifyContent: "Space-around",
          alignItems: "center",
        }}
      >
        <Card>
          <CardBody>
            <CardTitle>One Time Pickup</CardTitle>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle>Book Online</CardTitle>
                    {/* <CardSubtitle>Card subtitle</CardSubtitle> */}
                    Book your cardboard pickup in under a minute. Volumetric
                    pricing does apply over 100 ga.
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle>We grab your cardboard</CardTitle>
                    <CardSubtitle>Heave Ho!</CardSubtitle>
                    Our team will comeby to rid you of your inanimate pest.
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle>Enjoy the space!</CardTitle>
                    <CardSubtitle>Let the Prime boxes flow</CardSubtitle>
                    Your home is now ready to be filled with more cardboard!
                    (drop us a line in the future)
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <CardBody
              style={{
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Link to="/book">
                <Button size="lg">Book Now!</Button>
              </Link>
            </CardBody>
          </CardBody>
        </Card>
      </div>
      <div
        style={{
          justifyContent: "Space-around",
          alignItems: "center",
          marginTop: 50,
        }}
      >
        <Card>
          <CardBody>
            <CardTitle>Bi-weekly Cardboard pickup service</CardTitle>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle>Signup Online</CardTitle>
                    <CardSubtitle>$10/mo </CardSubtitle>
                    Signup for our Cardboard pickup service. We come by 2 times
                    a month, the timing is based on location
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle>We grab your cardboard</CardTitle>
                    <CardSubtitle>Heave Ho!</CardSubtitle>
                    Our team will comeby to rid you of your inanimate pest.
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle>No fixed terms!</CardTitle>
                    <CardSubtitle>Let the Prime boxes flow</CardSubtitle>
                    Cancel at anytime through our site for free.
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <CardBody
              style={{
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              {/* <Button size="lg" href="/signup">
            Signup!
          </Button> */}
            </CardBody>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
}

export default Pricing;
