import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import Backendless from "backendless";
import {
  Alert,
  Form,
  FormInput,
  FormGroup,
  FormTextarea,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "shards-react";
import Shape from "../components/PointedShape";
import AboutDetails from "../components/AboutDetails";
import ImageText from "../components/ImageText";
import BottomHeader from "../components/BottomHeader";
import { Image } from "react-bootstrap";
import cardboardPic from "../res/about-us/main-image.jpg";
import mushroomPic from "../res/landing-page/Mushroom.jpg";
import logYSprouts from "../res/landing-page/banner-imagethrd.jpg";
import headerImage from "../res/Contact-us/sub-header.jpg";
import map from "../res/cardboard-page/map.png";
import bgSilhouette from "../res/landing-page/bg.png";
import { SocialIcon } from "react-social-icons";

var validator = require("email-validator");
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

function CardboardServices(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container
      style={{
        position: "absolute",
        top: 0,
        left: -20,
        maxWidth: "220%",
        justifyContent: "center",
        alignSelf: "center",
      }}
      className="dr-example-container"
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
        }}
      >
        <Image
          style={{
            width: "100%",
          }}
          fluid
          src={headerImage}
        ></Image>
      </div>
      <div
        style={{
          width: "100%",
          position: "absolute",
          top: "15vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            fontWeight: "1000",
            fontSize: props.isMobile ? "5vw" : "3vw",
            color: "rgb(255,255,255)",
          }}
        >
          CARDBOARD SERVICES
        </h1>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: props.isMobile ? "column" : "row",
          justifyContent: "center",
          paddingTop: "30%",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: props.isMobile ? "80%" : "25%",
            justifyContent: "center",
            marginRight: props.isMobile ? "0" : "3em",
            marginLeft: props.isMobile ? "3em" : "0",
          }}
        >
          <Image
            style={{
              display: props.isMobile ? "block" : "none",
              // position: "absolute",
              // top: "55px",
              // left: "200px",
              // width: "800px",
              // height: "2px",
              // background: "#2c3f58",
              marginBottom: "2em",
              marginTop: "5vw",
              width: props.isMobile ? "75vw" : "30vw",
            }}
            fluid
            src={map}
          />
          <Card
            outline
            theme="light"
            style={{
              marginBottom: "2em",
              display: props.isMobile ? "block" : "none",
            }}
          >
            <CardBody>
              <CardTitle>LEGEND</CardTitle>
              <CardSubtitle style={{}}>SUB/OTP</CardSubtitle>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    background: "rgb(168,212,140)",
                    width: "20px",
                    height: "20px",
                  }}
                />
                &nbsp;&nbsp;&nbsp;Zone 1: $15/$20
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    background: "rgb(198,217,153)",
                    width: "20px",
                    height: "20px",
                  }}
                />
                &nbsp;&nbsp;&nbsp;Zone 2: $20/$25
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    background: "rgb(232,232,190)",
                    width: "20px",
                    height: "20px",
                  }}
                />
                &nbsp;&nbsp;&nbsp;Zone 3: $25/$30
              </div>
            </CardBody>
          </Card>
          <Card
            outline
            theme="light"
            style={{
              marginBottom: "1.5em",
              height: "45%",
              marginTop: "5vw",
            }}
          >
            <CardBody>
              <CardTitle
                style={{
                  paddingBottom: "1.5em",
                }}
              >
                ONE TIME-PICKUP SERVICE (OTP)
              </CardTitle>
              <CardSubtitle
                style={{
                  paddingBottom: "1.5em",
                }}
              >
                We Take your cardboard, no strings attached!
              </CardSubtitle>
              <p
                style={{
                  paddingBottom: "1em",
                }}
              >
                Signing up for a one-time pickup is quick and easy: at the click
                of a button, we’ll come take your trash. We currently accept all
                cardboard and paper waste products, regardless of food soiling,
                ink, or wax coating. No need to rip tape or break down boxes
                either, we’ll do that for you!
              </p>
              <CardSubtitle
                style={{
                  paddingBottom: "1em",
                }}
              >
                Standard rate ranges from $20-$30.
              </CardSubtitle>
              <Link to="/book">
                <Button theme="success">Book Now</Button>
              </Link>
              <p
                style={{
                  fontSize: "12px",
                  marginBottom: "0.5em",
                  paddingBottom: "8em",
                  paddingTop: "2em",
                }}
              >
                Please be aware that the price may vary based on volume and
                distance.
              </p>
            </CardBody>
          </Card>

          <Card
            outline
            theme="light"
            style={{
              marginBottom: "1em",
              height: "45%",
            }}
          >
            <CardBody>
              <CardTitle
                style={{
                  paddingBottom: "1.5em",
                }}
              >
                BI-WEEKLY PICKUP SERVICE (SUB)
              </CardTitle>
              <CardSubtitle
                style={{
                  paddingBottom: "1.5em",
                }}
              >
                Constantly have a lot of cardboard? We'll take it!
              </CardSubtitle>
              <p
                style={{
                  paddingBottom: "1em",
                }}
              >
                If you need more than a one-time pickup, our bi-weekly service
                might be right for you. Our bi-weekly subscription package
                includes two pickups every month at a reduced price.
              </p>
              <CardSubtitle
                style={{
                  paddingBottom: "1em",
                }}
              >
                Standard rate ranges from $15-$25.
              </CardSubtitle>

              <Link to="/signup">
                <Button theme="success">SIGN UP</Button>
              </Link>
              <p
                style={{
                  fontSize: "12px",
                  paddingTop: "2em",
                  marginBottom: "0.5em",
                }}
              >
                Please be aware that the price may vary based on volume and
                distance.
              </p>
            </CardBody>
          </Card>
        </div>
        <div
          style={{
            width: props.isMobile ? "75vw" : "30vw",
          }}
        >
          <Image
            style={{
              display: !props.isMobile ? "block" : "none",
              marginTop: "5vw",
              // position: "absolute",
              // top: "55px",
              // left: "200px",
              // width: "800px",
              // height: "2px",
              // background: "#2c3f58",
              width: "100%",
            }}
            fluid
            src={map}
          />

          <Card
            outline
            theme="light"
            style={{
              marginBottom: "2em",
              display: props.isMobile ? "none" : "block",
            }}
          >
            <CardBody>
              <CardTitle>LEGEND</CardTitle>
              <CardSubtitle style={{}}>SUB/OTP</CardSubtitle>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    background: "rgb(168,212,140)",
                    width: "20px",
                    height: "20px",
                  }}
                />
                &nbsp;&nbsp;&nbsp;Zone 1: $15/$20
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    background: "rgb(198,217,153)",
                    width: "20px",
                    height: "20px",
                  }}
                />
                &nbsp;&nbsp;&nbsp;Zone 2: $20/$25
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    background: "rgb(232,232,190)",
                    width: "20px",
                    height: "20px",
                  }}
                />
                &nbsp;&nbsp;&nbsp;Zone 3: $25/$30
              </div>
            </CardBody>
          </Card>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "relative",
            justifyContent: "space-between",
            marginBottom: "60px",
            paddingLeft: "5%",
            paddingTop: "20%",
          }}
        ></div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          width: "100%",
        }}
      >
        <BottomHeader isMobile={props.isMobile} />
      </div>
    </Container>
  );
}

export default CardboardServices;
