import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import { ButtonGroup, Button as Btn } from "@material-ui/core";
import Backendless from "backendless";
import Grid from "@material-ui/core/Grid";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import axios from "axios";
import {
  Alert,
  Form,
  FormInput,
  FormGroup,
  FormRadio,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "shards-react";
import { Image } from "react-bootstrap";

import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
let first = true;

const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

function Scheduler(props) {
  let btn = true;
  if (props.btn && props.btn === "none") btn = false;
  const [addingNew, setAddingNew] = useState(false);
  const [newData, setnewData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    tags: [
      {
        id: "d2fbcea4-72a5-4da2-3cd3-d91d7fc627d9",
        value: "Default",
        selected: true,
      },
      {
        id: "850cc211-2f16-9601-f41c-b2ed220ced7b",
        value: "Driver",
        selected: false,
      },
    ],
    unavailability: {},
    blockedDates: {},
  });
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeUnavailability, setEmployeeUnavailability] = useState([
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
  ]);
  const [employeeBlackout, setEmployeeBlackout] = useState([]);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const handleReset = () => {
    let dataHolder = { ...newData };
    dataHolder.name = "";
    dataHolder.email = "";
    dataHolder.phoneNumber = "";
    setnewData({ ...dataHolder });
    setSelectedIndex(-1);
    setEmployeeBlackout([]);
    setEmployeeUnavailability([
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0],
    ]);
  };
  const handleSave = () => {
    let holder = { ...employeeList[selectedIndex] };
    console.log(holder);
    holder.unavailability = { weekdays: [...employeeUnavailability] };
    holder.blockedDates = { dates: [...employeeBlackout] };
    console.log(holder);
    Backendless.Data.of("Employee")
      .save({ ...holder })
      .then(function (savedObject) {
        // console.log(savedObject);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleClick = () => {};
  const handleDayClick = (day, { selected }) => {
    const selectedDays = [...employeeBlackout];
    if (selected) {
      const selectedIndex = selectedDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(new Date(selectedDay), new Date(day))
      );
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(new Date(day).toString());
    }
    let sortedSelectedDays = selectedDays.slice().sort((a, b) => a - b);
    setEmployeeBlackout([...sortedSelectedDays]);
  };

  const handlePolling = () => {
    var queryBuilder = Backendless.DataQueryBuilder.create();
    queryBuilder.setPageSize(100);
    Backendless.Data.of("Employee")
      .find(queryBuilder)
      .then(function (result) {
        // console.log(result);
        let data = result;
        let tempArr = [];
        // console.log(Object.keys(data).length);
        for (let x = 0; x < Object.keys(data).length; x++) {
          tempArr.push(data[x]);
          // console.log(data[x]);
        }
        // console.log(JSON.stringify(tempArr, null, 4));
        setEmployeeList([...tempArr]);
      })
      .catch(function (error) {
        // use the .statusCode or .message on the error object
        // to see the details of the error
      });
    /*
    let intentId = "pi_1IsxxYD3bne3Hf8rXauqnAgZ"; // Search DB against this ID and change payment Status / Confirm Email
    let whereClause = "paymentIntentId = '" + intentId + "'";

    var queryBuilder = Backendless.DataQueryBuilder.create().setWhereClause(
      whereClause
    );

    Backendless.Data.of("OTP_home")
      .find(queryBuilder)
      .then(async function (valid) {
        console.log("RES: ", valid);
        if (valid.length >= 1) {
          if (valid[0].emailRecord === false) {
            var bodyParts = new Backendless.Bodyparts();
            let dataObject = {
              address: valid[0].address,
              contactMethodType: valid[0].contactMethodType,
              contactMethodValue: valid[0].contactMethodValue,
              placeId: valid[0].placeId,
              objectId: valid[0].objectId,
              pickUpDate: new Date(valid[0].pickUpDate),
              pickUpTime: valid[0].pickUpTime,
              distanceFromOrigin: valid[0].distanceFromOrigin,
              paymentStatus: true,
              job: valid[0].jobData,
            }; //collapse tags b4 saving "Default,Driver"
            let job = { ...dataObject.job };
            let tagString = "";
            let opTagString = "";
            for (let x = 0; x < job.tags.length; x++) {
              tagString += job.tags[x];
              if (x < job.tags.length - 1) {
                tagString += ",";
              }
            }
            for (let x = 0; x < job.opTags.length; x++) {
              opTagString += job.opTags[x];
              if (x < job.opTags.length - 1) {
                opTagString += ",";
              }
            }
            job.tags = tagString;
            job.opTags = opTagString;
            bodyParts.textmessage = JSON.stringify(dataObject, null, 4);
            bodyParts.htmlmessage = JSON.stringify(dataObject, null, 4);
            console.log(dataObject);
            await Backendless.Data.of("Jobs")
              .save(job)
              .then(function (savedObject) {
                console.log(savedObject);
              });

            // non-blocking call
            await Backendless.Messaging.sendEmail(
              "CHECKOUT CONFIRMATION",
              bodyParts,
              ["shaivkamat@takeitrecycling.ca"]
            ).then(function (response) {
              console.log("Checkout Confirmed");
            });
            await Backendless.Data.of("OTP_home")
              .save({
                objectId: valid[0].objectId,
                emailRecord: true,
                paymentStatus: true,
              })
              .then(function (savedObject) {});
          }
        } else {
          //no options left
          return;
        }
      })
      .catch(function (fault) {
        // an error has occurred, the error code can be retrieved with fault.statusCode
      });
      */
  };

  const setClicked = (day, index) => {
    // console.log(employeeUnavailability);
    let holder = [...employeeUnavailability];
    holder[day][index] = !holder[day][index];
    // console.log(holder);
    setEmployeeUnavailability([...holder]);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    if (Object.keys(employeeList[index].blockedDates).length !== 0) {
      setEmployeeBlackout([...employeeList[index].blockedDates.dates]);
    } else {
      setEmployeeBlackout([]);
    }
    if (Object.keys(employeeList[index].unavailability).length !== 0) {
      setEmployeeUnavailability([
        ...employeeList[index].unavailability.weekdays,
      ]);
    } else {
      setEmployeeUnavailability([
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
      ]);
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handlePhoneChange(contactValue) {
    let dataHolder = { ...newData };
    dataHolder.phoneNumber = contactValue.target.value;
    setnewData({ ...dataHolder });
  }

  function handleNameChange(contactValue) {
    let dataHolder = { ...newData };
    dataHolder.name = contactValue.target.value;
    setnewData({ ...dataHolder });
  }

  function handleEmailChange(contactValue) {
    let dataHolder = { ...newData };
    dataHolder.email = contactValue.target.value;
    setnewData({ ...dataHolder });
  }
  function clickedTag(index) {
    let dataHolder = { ...newData };
    dataHolder.tags[index].selected = !dataHolder.tags[index].selected;
    setnewData({ ...dataHolder });
  }

  function addNewEmployee() {
    let dataHolder = { ...newData };
    dataHolder.tags = "";
    if (newData.tags[0].selected) {
      dataHolder.tags += newData.tags[0].value;
    }
    if (newData.tags[1].selected) {
      if (newData.tags[0].selected) {
        dataHolder.tags += ",";
      }
      dataHolder.tags += newData.tags[1].value;
    }
    console.log(JSON.stringify(dataHolder, null, 4));
    Backendless.Data.of("Employee")
      .save(dataHolder)
      .then(function (savedObject) {
        console.log(JSON.stringify(savedObject, null, 4));
      })
      .catch(function (error) {
        console.log(JSON.stringify(error, null, 4));
      });
    setnewData({
      name: "",
      email: "",
      phoneNumber: "",
      tags: [
        {
          id: "d2fbcea4-72a5-4da2-3cd3-d91d7fc627d9",
          value: "Default",
          selected: false,
        },
        {
          id: "850cc211-2f16-9601-f41c-b2ed220ced7b",
          value: "Driver",
          selected: false,
        },
      ],
      unavailability: {},
      blockedDates: {},
    });
  }
  //polling btn to pull list and keep it in
  return (
    // <Container className="dr-example-container">
    // <div
    //   style={{
    //     display: "flex",
    //     flexDirection: props.isMobile ? "column" : "row",
    //     width: "90%",
    //   }}
    // >
    <Card>
      <CardBody>
        <CardBody
          style={{
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Button
            style={{ display: !addingNew ? "block" : "none" }}
            size="lg"
            onClick={() => {
              setAddingNew(true);
            }}
          >
            Add New
          </Button>
          <div
            style={{
              display: addingNew ? "flex" : "none",
              flexDirection: "row",
            }}
          >
            <FormInput
              placeholder="Name"
              onChange={handleNameChange}
              className=""
            />
            <FormInput
              placeholder="Email"
              onChange={handleEmailChange}
              className=""
            />
            <FormInput
              placeholder="Phone Number"
              onChange={handlePhoneChange}
              className=""
            />
            {newData.tags.length > 0 && (
              <div
                style={{
                  display: addingNew ? "flex" : "none",
                  flexDirection: "row",
                }}
              >
                <ButtonGroup
                  size="small"
                  aria-label="small outlined button group"
                >
                  <Button
                    theme={!newData.tags[0].selected ? "light" : "success"}
                    onClick={() => {
                      clickedTag(0);
                    }}
                  >
                    {newData.tags[0].value}
                  </Button>

                  <Button
                    theme={!newData.tags[1].selected ? "light" : "success"}
                    onClick={() => {
                      clickedTag(1);
                    }}
                  >
                    {newData.tags[1].value}
                  </Button>
                </ButtonGroup>
              </div>
            )}

            <Button
              onClick={() => {
                setAddingNew(false);
                addNewEmployee();
              }}
            >
              Add Employee
            </Button>
          </div>
          <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
              <ButtonGroup
                variant="contained"
                color="primary"
                ref={anchorRef}
                aria-label="split button"
              >
                <Button onClick={handleClick}>
                  {selectedIndex === -1
                    ? "Load Employees"
                    : employeeList[selectedIndex].name}
                </Button>
                <Button
                  color="primary"
                  size="small"
                  aria-controls={open ? "split-button-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                >
                  <ArrowDropDownIcon />
                </Button>
                <Button onClick={handlePolling}>
                  <span>Get List</span>
                </Button>
              </ButtonGroup>
              {employeeList.length > 0 && (
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList id="split-button-menu">
                            {employeeList.map((data, index) => (
                              <MenuItem
                                key={employeeList[index].objectId}
                                disabled={index === selectedIndex}
                                selected={index === selectedIndex}
                                onClick={(event) =>
                                  handleMenuItemClick(event, index)
                                }
                              >
                                {employeeList[index].name}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              )}
            </Grid>
          </Grid>
          {selectedIndex !== -1 && (
            <Grid container direction="column" alignItems="center">
              <CardTitle>{employeeList[selectedIndex].name}</CardTitle>
              <CardTitle>{employeeList[selectedIndex].email}</CardTitle>
              <CardTitle>{employeeList[selectedIndex].phoneNumber}</CardTitle>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {weekdays.map((day, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "1em",
                      }}
                    >
                      <CardTitle>{day}</CardTitle>

                      <Button
                        style={{
                          marginBottom: "1em",
                        }}
                        theme={
                          !employeeUnavailability[index][0]
                            ? "light"
                            : "success"
                        }
                        onClick={() => {
                          setClicked(index, 0);
                        }}
                      >
                        12
                      </Button>

                      <Button
                        style={{
                          marginBottom: "1em",
                        }}
                        theme={
                          !employeeUnavailability[index][1]
                            ? "light"
                            : "success"
                        }
                        onClick={() => {
                          setClicked(index, 1);
                        }}
                      >
                        2
                      </Button>

                      <Button
                        style={{
                          marginBottom: "1em",
                        }}
                        theme={
                          !employeeUnavailability[index][2]
                            ? "light"
                            : "success"
                        }
                        onClick={() => {
                          setClicked(index, 2);
                        }}
                      >
                        4
                      </Button>

                      <Button
                        style={{
                          marginBottom: "1em",
                        }}
                        theme={
                          !employeeUnavailability[index][3]
                            ? "light"
                            : "success"
                        }
                        onClick={() => {
                          setClicked(index, 3);
                        }}
                      >
                        6
                      </Button>
                    </div>
                  );
                })}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <DayPicker
                  onDayClick={handleDayClick}
                  selectedDays={employeeBlackout.map((date, index) => {
                    return new Date(date);
                  })}
                  disabledDays={[
                    { daysOfWeek: [0, 6] },
                    { before: new Date() },
                  ]}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "1em",
                  }}
                >
                  <CardTitle>BlackOut Days</CardTitle>
                  {employeeBlackout.map((data) => {
                    return (
                      <CardSubtitle
                        style={{
                          marginBottom: "1em",
                        }}
                      >
                        {new Date(data).toString()}
                      </CardSubtitle>
                    );
                  })}
                </div>
              </div>

              <ButtonGroup
                size="small"
                aria-label="small outlined button group"
              >
                <Button
                  onClick={() => {
                    handleSave();
                  }}
                >
                  Save
                </Button>

                <Button
                  onClick={() => {
                    handleReset();
                  }}
                >
                  Reset
                </Button>
              </ButtonGroup>
            </Grid>
          )}
        </CardBody>
      </CardBody>
    </Card>
    // </div>
    // </Container>
  );
}

export default Scheduler;
